
.login{

    text-align:center;
    font-size:10px

}

.login img{

    padding : 10vh 0vh 50px 0vh ;
    width: 40em;
    align-content: center;

}

.login-cont{
    display:flex;
    flex-direction: column;
    
    font-size : 2em; 
    font-weight: 700;
}


.usertype{

    display: flex ; 
    flex-direction: row;
    justify-content :center ;
}

.usertype div{
    
    padding : 30px 0px ; 
    margin : 0px; 

}
.usertype img {

    width : 200px; 
    padding : 10px; 
    margin : 0px ;     
    cursor : pointer ; 
}


.login-sns{
    margin: 40px ; 
    display: flex;
    justify-content:center;
    flex-direction: row;

}

.login-sns img{
    padding : 10px ; 
    margin : 10px ;
    width:50px;
    border-radius: 50%;
    background-color: grey;
    object-fit:scale-down;
    cursor:pointer; 
    
}