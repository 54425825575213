@charset "utf-8";
#adm-wrap {  max-width: 1500px;  overflow: auto; }
#adm {width: 100%; height: 100%; padding-left: 250px; padding-top: 70px;}
#adm-header {  z-index: 100; background: #fff; padding: 20px; border-bottom: solid 1px #000;}
#adm-header:after {content: ''; display: table; clear: both;}
#adm-header h2 {float: left;}
#adm-header h2 a {display: block; text-indent: -9999px; width: 157px; height: 29px; background: url('../img/common/gnb_logo.png')no-repeat center center / contain;}
#adm-header dl {float: right;}
#adm-header dl:after {content: ''; display: table; clear: both;}
#adm-header dl dt {float: left; height: 29px; line-height: 29px; font-size: 14px; margin-right: 15px;}
#adm-header dl dd {float: left;}
#adm-header dl dd a {display: block; height: 29px; line-height: 27px; padding: 0 15px; border: solid 1px #000; text-align: center; font-size: 13px; -webkit-border-radius: 20px; border-radius: 20px;}
#adm-footer {background: #f5f5f5; padding: 30px; position: relative;}
#adm-footer h5 {position: absolute; right: 30px; top: 50%; margin-top: -13px; display: block; text-indent: -9999px; width: 180px; height: 26px; opacity: 0.2; background: url('../img/common/gnb_logo.png')no-repeat center center / cover;}
#adm-aside { z-index: 2; left: 0; top: 70px; bottom: 0; width: 250px; border-right: solid 1px #000;}

#adm-main { display:flex ; flex-direction: row; justify-content: space-between; }

#adm-aside ul {}
#adm-aside ul li {border-bottom: solid 1px #000;}
#adm-aside ul li:last-child {}
#adm-aside ul li a {display: block; padding: 12px 20px; font-size: 15px; position: relative;}
#adm-aside ul li a.on {background: #000; color: #fff;}
#adm-aside ul li a.on:after {content: ''; display: block; width: 20px; height: 9px; position: absolute; right: 20px; top: 50%; margin-top: -5px; background: url('../img/sub/aside_arrow.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
/* #adm-aside ul li a span.new {display: inline-block; vertical-align: middle; width: 15px; height: 15px; line-height: 15px; margin-left: 5px; margin-top: -2px; text-align: center; font-size: 11px; background: #f00; color: #fff; font-family: 'dotum', sans-serif; font-weight: bold; -webkit-border-radius: 50%; border-radius: 50%;} */
#adm-aside ul li a span.new {display: inline-block; margin-left: 3px; text-indent: -9999px; width: 6px; height: 6px; background: #f00; -webkit-border-radius: 50%; border-radius: 50%;}
#adm-content {width: 100%; height: 100%; overflow: auto;  padding : 30px;  }
#adm-inner {padding: 10px 10px 50px; min-width: 1200px;}
#adm-title {font-size: 30px; padding-bottom: 20px;}


#adm-login {display: table; width: 100%; height: 100%;}
#adm-login > div {display: table-cell; width: 100%; vertical-align: middle;}
#adm-login #adm-footer {position: fixed; left: 0; right: 0; bottom: 0;}
.adm-login__box {width: 100%; max-width: 460px; margin: 0 auto;}
.adm-login__box h2 {display: block; width: 124px; height: 228px; margin: 0 auto; text-indent: -9999px; background: url('../img/common/gnb_logo.png')no-repeat center center / cover;}
.adm-login__form {margin-top: 80px;}
.adm-login__form th,
.adm-login__form td {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 10px;}
.adm-login__form th {width: 160px; border-right: solid 1px #000; font-size: 16px; font-weight: 400;}
.adm-login__form td {}
.adm-login__form td input {display: block; width: 100%; border: none;}
.adm-login__submit {display: block; width: 100%; height: 60px; line-height: 60px; font-size: 16px; text-align: center; background: #000; color: #fff; margin-top: 30px;}
.adm-login__info {margin-top: 10px; font-size: 14px; text-align: center;}



.adm-pg {text-align: center; margin-top: 30px;}
.adm-pg a {width: 30px; height: 30px; line-height: 30px; font-size: 14px; font-weight: 500; color: #9A9A9A;}
.adm-pg > a {display: inline-block; vertical-align: middle; text-indent: -9999px; opacity: 0.4; -webkit-transition: all 0.3s; transition: all 0.3s;}
.adm-pg > a:hover {opacity: 1;}
.adm-pg > a.prev {background: url('../img/common/paging_prev.png')no-repeat center center / 7px auto;}
.adm-pg > a.next {background: url('../img/common/paging_next.png')no-repeat center center / 7px auto;}
.adm-pg ul {display: inline-block; vertical-align: middle; margin: 0 15px;}
.adm-pg ul:after {content: ''; display: table; clear: both;}
.adm-pg ul li {float: left; margin: 0 5px;}
.adm-pg ul li a {display: block;}
.adm-pg ul li a.on {color: #000;}

.adm-tb {table-layout: fixed;}
.adm-tb th,
.adm-tb td:not(.adm-tb-item td ) {border-top: solid 1px #000; border-bottom: solid 1px #000; text-align: center; font-size: 14px; line-height: 10px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap;}
.adm-tb th {padding: 10px; font-weight: 500;}
.adm-tb td {padding: 5px 5px;}
.adm-tb__btn {display: inline-block;  background: none; margin: 5px ; width:70px; height: 26px; line-height: 24px; padding: 0 10px; border: solid 1px #000; font-size: 11px; border-radius: 20px;}
.adm-tb__btn.red{ color :#C20019; border-color:#C20019; } 
.adm-tb__btn:hover {background: #000; color: #fff;}
.adm-tb__btn:disabled {background:#fff; color:#d1d1d1; border-color: #d1d1d1; }
.adm-tb__h4 {display: inline-block; line-height: 30px; margin-right : 20px;  font-size:15px; background: #d1d1d1; border : solid 1px grey; width : 200px;  }


.adm-tm-wrap {position: fixed;  top: 0; right: 0; bottom: 0; left: 0; z-index: 10; background: rgba(0, 0, 0, 0.6);}

.adm-tm-box {  background-color: white;  display : flex; flex-direction: row; justify-content:space-around;  border:solid 1px black;  position:fixed; left: 230px; top: 500px ;   width:1200px; height : 150px; }
.adm-tm-box div{   display:flex; flex-direction: column; justify-content:space-between; width:50%; height:100%;  }
.adm-tm-box div ul{ display:flex; margin:10px; flex-direction: row;  width:100%; border-right: solid 1px black; padding-left:30px;  }
.adm-tm-box li{ width:80px;  text-align: left;  }
.adm-tm-box li span { position : absolute; right : 20px; font-size : 20px; cursor: pointer ;    }
.adm-tm-box li button { position : absolute; right : 80px; font-size : 12px; cursor: pointer ;  border: solid 1px black;   padding : 2px 5px; width : 80px; color:white; background: black;;  }
.adm-tm-box li h4 { width: 300px;  }
.adm-tm-box_profile {  display : flex; flex-direction: column; justify-content: space-between;   cursor:pointer; }
.adm-tm-box_profile img {width : 70px ; height : 70px ; object-fit:cover ; border-radius: 50%; }
.adm-tm-box_profile button {position: relative;  }
.adm-tm-box_profile p {width:100px;  }
.adm-tm-box_profile h3 {position : absolute; font-size : 100px; color:#C20019; top:0px;   }

.adm-more__btn { width : 100% ; text-align: center;}

.adm-more__btn button{  background: black; color : white ; height : 30px; width : 80px; font-size:14px; margin : 20px auto; }

.adm-modal__box { padding : 40px 20px 20px 20px ;  overflow-y:auto ; z-index: 100; position : fixed ; top:0; left: 0; right: 0; margin: 0 auto; width : 700px; height: 700px; background :white; border : solid 1px black;  }
.adm-modal__box h2{ font-size: 20px; position: relative; margin-left: 630px; cursor : pointer ; }



#PeopleInput { width :30px; border : solid 1px black; text-align: center;}

.adm-tb-item {   }
.adm-tb-item td { font-size: 12px ; line-height : 10px; height : 10px ; padding :3px 5px; }

.adm-calendar{  font-size: 5px;  }

.adm-tb__info {padding-bottom: 10px; margin-top: 30px;}
.adm-tb__info:after {content: ''; display: table; clear: both;}
.adm-tb__info ul {float: right;}
.adm-tb__info ul:after {content: ''; display: table; clear: both;}
.adm-tb__info ul li {margin-right: 5px; float: left;}
.adm-tb__info ul li:last-child {margin-right: 0;}
.adm-tb__info button {display: block; height: 32px; line-height: 30px; border: solid 1px #000; background: none; text-align: center; padding: 0 15px; font-size: 13px;}
.adm-tb__info button.grn {float: left; background: #0e8040; border-color: #0e8040; color: #fff;}
.adm-tb__info button.blue {float: left; background: blue; border-color:  blue; color: #fff;}
.adm-tb__info button.org {float: left; background: #a76c00; border-color: #a76c00; color: #fff;margin-right:50px; }
.adm-tb__info button.bk {float: left; background: #000; border-color: #000; color: #fff;}

.adm-sch {border: solid 1px #000; padding: 20px 17px;}
.adm-sch table {font-size: 14px;}
.adm-sch th,

.adm-sch td {text-align: left; padding: 0 3px;}
.adm-sch th {font-weight: 500;}
.adm-sch th.adm-sch__text {width: 60px;}
.adm-sch th.adm-sch__text2 {width: 80px; padding-right: 5px; text-align: right;}
.adm-sch th.adm-sch__text3 {width: 30px; padding-left: 2px;}
.adm-sch th.adm-sch__text4 {width: 40px; padding-right: 5px; text-align: right;}
.adm-sch td.adm-sch__sel {width: 120px;}
.adm-sch td.adm-sch__sel2 {width: 100px;}
.adm-sch td.adm-sch__submit {width: 100px; padding-left: 10px;}
.adm-sch td.adm-sch__submit button {display: block; width: 100%; height: 36px; line-height: 36px; text-align: center; background: #000; color: #fff; font-size: 14px;}
.adm-sch td.adm-sch__count {width: 90px;}
.adm-sch td.adm-sch__date {width: 200px; }
.adm-sch td.adm-sch__date2 {width: 15px; text-align: center;}

.adm-sch input,
.adm-sch select {display: block; width: 100%; font-size: 14px; border-color: #000;}



.adm_temp {position: fixed; width: 100%; left: 0; right: 0; padding-top: 70px; padding-left: 250px;}

#popup.adm_pop {text-align: left; top: 0; left: 0; width: 100%; height: 100%; padding: 40px 30px; overflow-y: auto;  background: none; border: none; transform: translate(0, 0); -webkit-transform: translate(0, 0);}
.pop-adm__box {background: #fff; border: solid 2px #000; width: 100%; max-width: 460px; margin: 0 auto; padding: 30px; position: relative;}
.pop-adm__box .pop-title {margin-bottom: 30px;}
.pop-adm__box .pop-btn {margin-top: 30px;}




.mypage-switch__toggle {display: inline-block; position: relative; width: 46px; height: 22px;}
.mypage-switch__toggle input {display: none;}
.mypage-switch__toggle span {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ddd; transition: 0.4s; border-radius: 34px;}
.mypage-switch__toggle span:before {position: absolute; content: ""; height: 18px; width: 18px; left: 2px; bottom: 2px; background-color: #fff; transition: 0.4s; border-radius: 50%;}
.mypage-switch__toggle input:checked + span {background-color: #30D87C;}
.mypage-switch__toggle input:checked + span::before {transform: translateX(24px);}



.adm-seq {text-align: center;}
.adm-seq li {display: inline-block;}
.adm-seq li button {display: block; background: none; width: 24px; height: 24px; line-height: 22px; text-align: center; font-size: 10px; border: solid 1px #666;}

.adm-ban__add {display: block; width: 223px; padding-bottom: 146px; background: #eee; position: relative;}
.adm-ban__add:before,
.adm-ban__add:after {content: ''; display: block; background: #fff; position: absolute; left: 50%; top: 50%;}
.adm-ban__add:before {width: 20px; height: 2px; margin-left: -10px; margin-top: -1px;}
.adm-ban__add:after {width: 2px; height: 20px; margin-left: -1px; margin-top: -10px;}
.adm-ban__thumb {display: block; width: 180px; padding-bottom: 0px; position: relative;}
.adm-ban__change {display: none;}
.adm-ban__change_label { display: block; width: 23px; height: 23px; position: absolute; right: 5px; bottom: 5px; text-indent: -9999px; background: #fff url('../img/sub/team-profile_setting.png')no-repeat 5px center / 11px auto; border: solid 1px #ddd; cursor: pointer; -webkit-border-radius: 50%; border-radius: 50%;}

.adm-wine_tr div {text-align: left;   }

.adm-ban__date {width: 300px; display: inline-block;}
.adm-ban__date:after {content: ''; display: table; clear: both;}
.adm-ban__date li {float: left; position: relative; width: 40%;}
.adm-ban__date li:nth-child(2) {width: 10%; height: 36px; line-height: 36px; text-align: center;}
.adm-datepicker {position: relative;}
.adm-datepicker input {display: block; width: 100%;}
.adm-datepicker img {position: absolute; right: 7px; top: 10px; cursor: pointer;}



.win-pop {padding: 30px !important;}
.win-pop .adm_temp {padding: 30px !important; top: 0;}
.win-pop__inner {width: 100%; max-width: 480px; margin: 0 auto; padding: 30px; border: solid 2px #000;}
.adm-res__sel {margin: 0 -4px;}
.adm-res__sel:after {content: ''; display: table; clear: both;}
.adm-res__sel li {float: left; width: 50%; padding: 0 4px;}
.adm-res__sel li button {display: block; width: 100%; height: 40px; line-height: 38px; text-align: center; background: none; border: solid 1px #000; font-size: 16px;}
.adm-res__sel li button:hover {background: #000; color: #fff;}
.adm-res__name {margin-top: 10px; background: #f5f5f5; text-align: center; padding: 20px; position: relative;}
.adm-res__name p {}
.adm-res__name button {display: block; width: 21px; height: 21px; border: solid 1px #000; background: none; text-indent: -9999px; position: absolute; right: 20px; top: 50%; margin-top: -10px;}
.adm-res__name button:before,
.adm-res__name button:after {content: ''; display: block; background: #000; width: 11px; height: 1px; position: absolute; left: 50%; top: 50%; margin-left: -6px;}
.adm-res__name button:before {transform:rotate(-45deg);-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);}
.adm-res__name button:after {transform:rotate(45deg);-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);}
.adm-res__time {}
.adm-res__time .adm-datepicker {display: inline-block; width: 120px; vertical-align: middle;}
.adm-res__zone {}
.adm-res__zone:after {content: ''; display: table; clear: both;}
.adm-res__zone li {float: left; margin-right: 30px;}
.adm-res__zone li:last-child {margin-right: 0;}
.adm-res__zone li select {margin-right: 5px;}
.adm-res__input {position: relative; padding-right: 20px;}
.adm-res__input input[type="text"] {display: block; width: 100%;}
.adm-res__input span {position: absolute; right: 0; bottom: 0;}

.adm-order__info {padding: 5px 10px; border: solid 1px #000;}
.adm-order__info table {table-layout: auto;}
.adm-order__info th,
.adm-order__info td {text-align: center; padding: 10px 0;}
.adm-order__info th h5 {font-size: 24px;}
.adm-order__info td {border-top: solid 1px #000;}

.adm-order__list {table-layout: auto; margin-top: 20px;}
.adm-order__list th,
.adm-order__list td {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 10px; text-align: center;}
.adm-order__list tfoot th {font-size: 16px;}

.adm-order__list--del {display: inline-block; width: 21px; height: 21px; border: solid 1px #000; background: none; text-indent: -9999px; position: relative;}
.adm-order__list--del:before,
.adm-order__list--del:after {content: ''; display: block; background: #000; width: 11px; height: 1px; position: absolute; left: 50%; top: 50%; margin-left: -6px;}
.adm-order__list--del:before {transform:rotate(-45deg);-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);}
.adm-order__list--del:after {transform:rotate(45deg);-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);}



/*adm-calendar Styling */
.adm-calendar {position: relative;}
.adm-calendar a{cursor: pointer;}
.adm-calendar.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
	supported by Chrome, Edge, Opera and Firefox */
}
.adm-calendar .month-year-btn {text-align: center; margin-bottom: 20px;}
.adm-calendar .month-year-btn .prev-month,
.adm-calendar .month-year-btn .next-month {display: inline-block; vertical-align: middle; width: 30px; height: 30px; text-indent: -9999px;}
.adm-calendar .month-year-btn .prev-month {background: url('../img/common/calendar_prev.png')no-repeat center center / 10px auto;}
.adm-calendar .month-year-btn .next-month {background: url('../img/common/calendar_next.png')no-repeat center center / 10px auto;}
.adm-calendar .month-year {display: inline-block; vertical-align: middle; width: 160px; font-size: 20px;}
.adm-calendar .month-year .year {display: inline;}
.adm-calendar .month-year .month {display: inline;}
.adm-calendar .day-names {border: solid 1px #000; border-left: none;}
.adm-calendar .day-names li {border-left: solid 1px #000;}
.adm-calendar .day-names .initials {text-align: center; padding: 5px; background: #f5f5f5;}
.adm-calendar ol:after {content: ''; display: table; clear: both;}
.adm-calendar ol li {float: left; width: 14.28571%;}
.adm-calendar .days {border-bottom: 1px solid #000; border-left: solid 1px #000;}
.adm-calendar .days li {min-height: 6rem; cursor: pointer; border-right: solid 1px #000; padding: 10px;}
.adm-calendar .days li:after {content: ''; display: table; clear: both;}
.adm-calendar .days li .date {font-size: 16px; float: left; position: relative; width: 30px; height: 30px; line-height: 26px; margin-left: -5px; margin-top: -5px;border: solid 2px transparent; text-align: center; -webkit-border-radius: 50%; border-radius: 50%;}
.adm-calendar .days li:nth-child(n+29) {border-bottom: none;}
.adm-calendar .days li.outside .date  {color: #ddd;}
.adm-calendar .days li.today .date  {color: #C20019; position: relative; border-color: #C20019; background: #C20019; color: #fff;}

.adm-calendar .days li .events {float: right; margin-top: 40px;}
.adm-calendar .days li .events .event {margin-bottom: 5px;}
.adm-calendar .days li .events .event:last-child {margin-bottom: 0;}
.adm-calendar .days li .event {display: block;}
.adm-calendar .days li .event:after {content: ''; display: table; clear: both;}
.adm-calendar .days li .event span {display: block; width: 8px; height: 8px; -webkit-border-radius: 50%; border-radius: 50%; position: relative;}
.adm-calendar .days li .event.on span {background: #C20019;}
.adm-calendar .days li .event.off span {background: #ddd;}
.adm-calendar .days li .event span em {white-space: nowrap; display: none; font-style: normal; position: absolute; right: 100%; bottom: 0; margin-right: 3px; font-size: 12px; background: #fff; border: solid 1px #000; padding: 2px 5px; -webkit-border-radius: 4px; border-radius: 4px;}
.adm-calendar .days li .event span em b {display: block;}
.adm-calendar .days li .event span:hover em {display: block;}

.adm-sch__cancel {position: relative;}
.adm-sch__cancel button {position: relative; right: 0; top: 100%; z-index: 1; display: block; height: 40px; line-height: 38px; background: none; border: solid 1px #000; padding: 0 20px; font-size: 14px;}
.adm-sch__cancel ul {position: relative; left: 0; top: 100%; margin-top: 20px; z-index: 1;}
.adm-sch__cancel ul:after {content: ''; display: table; clear: both;}
.adm-sch__cancel ul li {float: left; font-size: 14px; position: relative; padding-left: 20px; margin-right: 20px;}
.adm-sch__cancel ul li:last-child {margin-right: 0;}
.adm-sch__cancel ul li:before {content: ''; display: block; position: absolute; left: 0; top: 50%; margin-top: -6px; width: 12px; height: 12px; margin-right: 5px; -webkit-border-radius: 50%; border-radius: 50%;}
.adm-sch__cancel ul li:first-child:before {background: #C20019;}
.adm-sch__cancel ul li:last-child:before {background: #ddd;}

.adm-sch__time { display:flex; flex-direction:row; justify-content: center; line-height: 30px; }
.adm-sch__time input {width: 50px;  }

.adm-imsiteam_btn { width : 80px; height : 25px ; background-color: black; cursor:pointer; color:white ;  margin-left : 20px; }
.adm-imsiteam_btn:hover { background : grey; }

.adm-sch__artist div { width : 300px; border-bottom: solid 2px black; background: #eee; line-height : 30px; font-size:1.5rem; margin: 0 2rem ;  }
.adm-sch__artist {display:flex ; flex-direction:row ; justify-content : center ; }
.adm-sch__artist li { cursor : pointer; float: center; margin: 3px 5px 3px 0px; border: solid 1px #000; padding: 5px 15px 5px 15px; -webkit-border-radius: 30px; border-radius: 30px;}
.adm-sch__artist li:hover {background:#000; color:#ddd}
.adm-sch__artist li span {display: block; float: left; height: 21px; line-height: 21px; font-size: 12px;}
.adm-sch__artist li button {display: block; float: left; width: 21px; height: 21px; margin-left: 5px; background: none; text-indent: -9999px; position: relative;}
.adm-sch__artist li button:before,
.adm-sch__artist li button:after {content: ''; display: block; background: #000; width: 11px; height: 1px; position: absolute; left: 50%; top: 50%; margin-top: -1px; margin-left: -6px;}
.adm-sch__artist li button:before {transform:rotate(-45deg);-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);}
.adm-sch__artist li button:after {transform:rotate(45deg);-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);}


.adm-order__wrap{display:flex; flex-direction: column; }
.adm-order__upper{display:flex ; flex-direction: row; width : 100%; }
.adm-order__bottom{display:flex ; flex-direction: row; width : 100%;}
.adm-order__container{ display: flex ; flex-direction: row; justify-content: left; height : 40vh ; border : solid 1px; width:80%; }
.adm-order__complete{ display: flex ; flex-direction: row; justify-content: left; height : 40vh ; border : solid 1px; width:20%; }
.adm-order__complete tr{border-bottom : solid 1px #d1d1d1; line-height: 40px;  }
.adm-order__box{ border : solid 1px black; overflow-y: auto; overflow-x: auto;}
.adm-order__box h2{ position: absolute; top:70px;  border-bottom: solid 1px #d1d1d1; color: #C20019; }
.adm-order__box tr{ border-bottom : solid 1px #d1d1d1; line-height: 40px;  }
.adm-order__first{ text-align : center ; width:100% ; border-bottom : solid 1px #d1d1d1; line-height: 40px;  }
.adm-order__td_1{ width : 10%; }
.adm-order__td_2{ width : 60%; }
.adm-order__td_3{ width : 10%; }
.adm-order__td_4{ width : 10%; }
.adm-order__td_5{ width : 10%;  }
.adm-order__container button{ width: 40px; height: 30px ; background : black; color:white; border: solid 1px white;  }

.adm-order__title{ cursor:pointer;}

.adm-server__container{ height: 55vh; display: flex; flex-direction: row; justify-content: space-between;}
.adm-server__box{border: solid 1px black ; width : 100% ; height:100%; display:inline-block; overflow-y: auto; }
.adm-server__box h2{ text-align: center; margin: 10px; border-bottom: solid 1px #d1d1d1; }
.adm-server__box  button{ width: 50px; height: 30px ; background : black; color:white; border: solid 1px white;  }

.adm-order_sts__box button{ width: 50px; height: 30px ; background : black; color:white; border: solid 1px white;  }


.adm-order__map--cont{  position : relative; height : 60%; width : 390px;  margin:0px auto; overflow-y: auto;  }

.adm-order_sts__box{border: solid 1px black ; width : 100% ;height : 10 0%; display:inline-block; overflow-y: auto; }
.adm-order_sts__box h2{ text-align: center; margin: 10px; border-bottom: solid 1px #d1d1d1; }

.adm-order_sts__box tr{ border-bottom : solid 1px #d1d1d1; line-height: 40px; }

.adm-res-wrap{position: fixed;  top: 0; right: 0; bottom: 0; left: 0; z-index: 10; background: rgba(0, 0, 0, 0.6); }
.adm-res-box{  display:flex; flex-direction: column;  background-color: white;  justify-content:space-around;  border:solid 1px black;  position:fixed; left: 0;right:0; top: 0; bottom : 0  ; margin: auto ;  width:800px; height : 150px; }
.adm-res__ul{ display: flex; flex-direction: row ; justify-content: space-between; margin : 20px; font-size : 18px;  }
.adm-res__ul li{font-size : 12px; }
.adm-res__ul li label{margin : 10px; }
.adm-res__ul li span{ padding : 10px; }
.adm-res__ul li button { font-size : 12px; cursor: pointer ;  border: solid 1px black;   padding : 2px 5px; width : 80px; height:35px; border-radius: 20px ;}
.adm-res__ul li button:hover{ color:white; background-color: #000; }
.adm-res__x{cursor:pointer;  font-size : 20px; }

.adm-order__popup{  position: fixed; width:800px; height:700px;  top: 0; right: 0; bottom: 0; left: 0; z-index: 99; background:  #F4F4F4;margin : 0 auto ; top : 50px ;   }
 
.adm-orderpopup__wrap{ display: flex; flex-direction: column; justify-content: space-evenly; height:700px;  }
.adm-orderpopup__container{ display: flex; flex-direction: row; justify-content: space-between;height:90%;  }
.adm-orderpopup__desc{  }
.adm-orderpopup__desc h3{ padding:30px 0px 0px 30px; font-size:20px; color:black;    }
.adm-orderpopup__text h3{padding:30px 0px 0px 30px; font-size:30px; color:#C20019 }
.adm-orderpopup__seat{ background-color: red; z-index: 5 ; }
.adm-orderpopup__button  { cursor:pointer;  color:#d1d1d1; font-size:30px; text-align:center; background-color: #000;   margin: 20px; height: 50px;  }
.adm-seat__status1{ width : 100px; border-radius: 20px; background:aquamarine; }

.adm-order_dtl_wrap { position: absolute; top: 0; left: 0; right: 0; bottom:0 ;  z-index: 99;  background-color: rgba(0, 0, 0, 0.6);  }
.adm-order_dtl_cont{ position : fixed ; overflow-y:auto ; width : 600px ; height : 580px ; left : 0 ; right : 0; margin : 0 auto ; top : 50px ;   background-color : #F4F4F4; display : flex; flex-direction: column; padding : 15px; }
.adm-order_dtl_cont th{ border-bottom: solid 1px #d1d1d1;padding: 0px 0px 15px 0px;    }
.adm-order_dtl_cont td{  padding : 15px; }

.adm-order_dtl_cont h4{  bottom : 0;  font-size : 20px; text-align: right; cursor:pointer; } 

.adm-order_dtl_right{ text-align: right;}

.adm-order_dtl_amt{ display: flex ; flex-direction: row; justify-content: right;}
.adm-order_dtl_amt h4{ font-size: 15px; margin : 10px; }

.adm-stat-wrap{ width:100%; height:100vh; }
.adm-stat-wrap h4{ font-size : 15px; margin : 30px 0px 10px 0px; text-align: center; }
.adm-stat-cont{ display:flex; flex-direction: row; justify-content: center; width:100%; }
.adm-stat-contdiv{ width:50% ;  }

