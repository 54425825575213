@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Serif&display=swap');


.title {position: absolute; width: 0; height: 0; text-indent: -9999px; left: -10000px; top: -100000px; overflow: hidden;}
.wrap {width: 100%; max-width: 1424px;  transition: all 0.3s;}

/* Reset */
* {/* outline:0 !important; outline-color: black;*/  box-sizing:border-box; -webkit-font-smoothing: antialiased;}
html,body,h1,h2,h3,h4,h5,h6,div,p,blockquote,pre,code,address,ul,ol,li,menu,nav,section,article,aside,
dl,dt,dd,table,thead,tbody,tfoot,label,caption,th,td,form,fieldset,legend,hr,input,button,textarea,object,figure,figcaption {margin:0;padding:0;}
html, body{width:100%; font-size:10px;}
html{-webkit-touch-callout:none; /* -webkit-user-select:none; */ -webkit-tap-highlight-color:rgba(0, 0, 0, 0);}
body{width:100%; background:#fff; min-width:320px; -webkit-text-size-adjust:none;word-wrap:break-word;word-break:break-all;}
body,input,select,textarea,button {border: none; font-size:12px; font-family: 'Trade Gothic LT ', 'Noto Sans KR', sans-serif; font-weight: 400; color:#000; letter-spacing: -0.05em; line-height: 1.6;}
ul,ol,li{list-style:none;}
table{width:100%;border-spacing:0;border-collapse:collapse; table-layout: fixed;}
img,fieldset{border:0;}
address,cite,code,em{font-style:normal;font-weight:400;}
label,img,input,select,textarea,button{vertical-align:middle;}
.hide,caption,legend{line-height:0;font-size:1px;overflow:hidden;}
hr{display:none;}
main,header,section,nav,footer,aside,article,figure{display:block;}
a{color:inherit;text-decoration:none;}
h1,h2,h3,h4,h5,h6 {font-weight: 700;}


/* Home Start */

/* Form */
input::-webkit-input-placeholder{color:#999; font-size:inherit;}


input[type=tel],
input[type=text],
input[type=password],
input[type=email], 
input[type=search] {height: 36px; padding: 0 10px; border: solid 1px #ddd; -webkit-appearance:none; -moz-appearance:none; appearance:none;}

input[type=tel][readonly],
input[type=text][readonly],
input[type=password][readonly],
input[type=email][readonly], 
input[type=search][readonly], 
input[type=tel][disabled],
input[type=text][disabled],
input[type=password][disabled],
input[type=search][disabled],
input[type=email][disabled]{background:#f5f5f5; border-color:#c0c0c0; color:#666;}
textarea[readonly],
textarea[disabled]{color:#666;}
input[type=submit] {border-radius: 0px 0px 0px 0px; -moz-border-radius: 0px 0px 0px 0px; -webkit-border-radius: 0px 0px 0px 0px;}


input[type="checkbox"] {display: inline-block; vertical-align: middle; width: 14px; height: 14px; border: solid 1px #000; position: relative; cursor: pointer; -webkit-appearance:none; -moz-appearance:none; appearance:none;}
input[type="checkbox"]:checked {background: #000 url('./img/common/checkbox.png')no-repeat left top / 12px auto;}
input[type="checkbox"] + label {display: inline-block; vertical-align: middle; margin-left: 5px; cursor: pointer;}

input[type="radio"] {display: inline-block; vertical-align: middle; width: 18px; height: 18px; border: solid 1px #999; position: relative; cursor: pointer; -webkit-appearance:none; -moz-appearance:none; appearance:none; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%;}
input[type="radio"]:checked:after {content: ''; display: block; background: #000; position: absolute; left: 4px; right: 4px; top: 4px; bottom: 4px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%;}
input[type="radio"] + label {display: inline-block; vertical-align: middle; margin-left: 5px; cursor: pointer;}

input[type="file"] {}

select {height: 36px; padding-left: 10px; border: solid 1px #d9d9d9; cursor: pointer; padding-right: 22px; background: #fff url('./img/common/select_arrow.png')no-repeat center right 6px / 9px auto; -webkit-appearance:none; -moz-appearance:none; appearance:none;}


textarea {resize: none; display: block; width: 100%; border: solid 1px #ddd; padding: 10px;}
button {cursor: pointer; -webkit-appearance:none; -moz-appearance:none; appearance:none;}


.main-title {font-size: 30px; color: #C20019; padding: 20px 0 10px; line-height: 1;}


a:focus,
button:focus,
select:focus,
input:focus,
.slick-slide:focus,
*[tabindex]:focus {/* outline: #000 solid 2px !important; */}


/* common */
#wrap {position: relative; width: 100%; max-width: 1440px; margin: 0 auto; padding-top: 140px; padding-left: 50px; padding-right: 50px; -webkit-transition: all 0.3s; transition: all 0.3s;}
#wraplogin {height: 100%; padding-top: 0; display : flex ;  flex-direction : column; justify-content: center; padding-left: 50px; padding-right: 50px; }
#wrap.join {padding-top: 94px;}
.hidden {position: absolute; left: -10000px; top: auto; height: 1px; overflow: hidden;}
#contents.main {padding-top: 20px; padding-bottom: 50px;}
#contents.sub {padding-bottom: 100px;}
#contents.join {padding: 100px 0;}
#contents.login {display: table; width: 100%;}
html.lock, body.lock {display: block; width: 100%; height: 100%; overflow: hidden;}
html, body {overflow-x: hidden;}
.body_temp {position: fixed; width: 100%; max-width: 1440px; margin: 0 auto; left: 0; right: 0; padding-top: 140px; padding-left: 50px; padding-right: 50px;}


/* Home End */ 

/* Main Menu Start */

#menu {border-top: solid 1px #000; border-bottom: solid 1px #000;}
	.menu-home {display: none;}
	.menu-user {}
	.menu-user__login {position: absolute; right: 40px; bottom: 65px; -webkit-transition: all 0.3s; transition: all 0.3s;}
	.menu-user__login:after {content: ''; display: table; clear: both;}
	.menu-user__login li {float: left; margin-right: 10px;}
	.menu-user__login li:last-child {margin-right: 0;}
	.menu-user__login li a {display: block; height: 34px; line-height: 32px; padding: 0 20px; border: solid 1px #000; -webkit-border-radius: 20px; border-radius: 20px; -webkit-transition: all 0.3s; transition: all 0.3s;}
	.menu-user__profile {position: absolute; right: 90px; bottom: 1px; border-left: solid 1px #000; padding: 8px 25px; -webkit-transition: all 0.3s; transition: all 0.3s;}
	.menu-user__profile:after {content: ''; display: table; clear: both;}
	.menu-user__profile em {background: url('./img/common/user_sample.png')no-repeat center center / cover; display: block; width: 27px; height: 27px; float: left; text-indent: -9999px; overflow: hidden; -webkit-border-radius: 50%; border-radius: 50%;}
	.menu-user__profile h5 {float: left; margin-left: 10px; height: 27px; line-height: 25px; font-size: 12px; font-weight: 400;}
	.menu-user__profile h5 span {font-weight: 700; margin-right: 3px;}
	.menu-user__profile p {display: none;}
	.menu-list {}
	.menu-list:after {content: ''; display: table; clear: both;}
	.menu-list > li {float: left; margin-right: 50px;}
	.menu-list > li:last-child {float: right; margin-right: 0; width: 90px; border-left: solid 1px #000; -webkit-transition: all 0.3s; transition: all 0.3s;}
	.menu-list > li > button {display: none;}
	.menu-list > li > a {display: block; height: 43px; line-height: 43px; font-size: 15px; font-weight: 700; -webkit-transition: all 0.3s; transition: all 0.3s;}
	.menu-list > li > a:hover {color: #C20019;}
	.menu-list > li:last-child > a {font-size: 12px; text-align: center;}
	.menu-list > li > ul {display: none !important;}
	#hd-mo {display: none;}
	#hd.scroll {padding-top: 50px;}
	#hd.scroll #hd-logo {bottom: 50px;}
	#hd.scroll #hd-logo a {width: 160px; height: 23px;}
	#hd.scroll .menu-user__login {bottom: 45px; right: 0;}
	#hd.scroll .menu-user__login li a {height: 30px; line-height: 28px; padding: 0 15px;}
	#hd.scroll .menu-user__profile {padding: 3px 15px; right: 70px;}
	#hd.scroll .menu-list > li:last-child {width: 70px;}
	#hd.scroll .menu-list > li > a {height: 33px; line-height: 33px;}	

/* Main Menu End */




/* Main Banner Start */

/* 메인 */
.main-event {background: #000; position: relative; margin-top:20px;}
.main-event__slider {  }
.main-event__slider--banner {}
.main-event__slider--banner:after {content: ''; display: table; clear: both;}
.main-event__slider--img {float: left; width: 50%; height: 440px;}
.main-event__slider--text {float: left; width: 50%; height: 440px; padding: 20px; position: relative;}
.main-event__slider--text em {display: block; font-size: 20px; color: #C20019; margin-top: 40px; font-family: 'Noto Serif', serif;}
.main-event__slider--text h4 {font-size: 60px; color: #fff; line-height: 1.0; font-weight: 400;}
.main-event__slider--text h4 span {display: block;}
.main-event__slider--text h4 span:before {content: '-';}
.main-event__slider--text p {font-size: 30px; color: #fff; position: absolute; left: 20px; bottom: 20px;}
.main-event__slider .slick-active em {animation:fadeIn 1.5s both 1s;}
.main-event__slider .slick-active h4 {animation:fadeIn 1.5s both 1.5s;}
.main-event__slider .slick-active p {animation:fadeIn 1.5s both 1s;}
.main-event__control {position: absolute; right: 20px; bottom: 30px; z-index: 1;}
.main-event__arrow {position: absolute; right: 0; bottom: 100%; margin-bottom: 20px; width: 90px;}
.main-event__arrow:after {content: ''; display: table; clear: both;}
.main-event__arrow button {display: block; float: left; width: 40px; height: 40px; margin-right: 5px; border: solid 1px #fff; text-indent: -9999px; -webkit-border-radius: 50%; border-radius: 50%;}
.main-event__arrow button:last-child {margin-right: 0;}
.main-event__arrow button.main-event__prev {background: url('./img/main/main_banner_arrow_prev.png')no-repeat center center / 8px auto;}
.main-event__arrow button.main-event__next {background: url('./img/main/main_banner_arrow_next.png')no-repeat center center / 8px auto;}
.main-event__dots {position:absolute; ; top : 90% ; left:70%; z-index:99;}
.main-event__dots:after {content: ''; display: table; clear: both;}
.main-event__dots li {float: left; margin-right: 20px;  }
.main-event__dots li:last-child {margin-right: 0;}
.main-event__dots li:after {content: ''; display: inline-block; vertical-align: middle; height: 1px; width: 0px; background: #fff; transition: all .3s ease-in-out; -webkit-transition: all .3s ease-in-out;}
.main-event__dots li button {display: inline-block; vertical-align: middle; color: #fff; background: none; margin: 0 10px;}
.main-event__dots li.slick-active:after {width: 100px;}



/* Main Banner End */


/* PreReservation Start */ 

.main-sch__list {}
.main-sch__list__link {display: table; width: 100%; border-top: solid 1px #000; padding: 10px 0 9px;}
.main-sch__list__num {display: table-cell; vertical-align: middle; width: 90px; text-align: center; font-size: 18px;}
.main-sch__list__thumb {display: table-cell; vertical-align: middle; width: 180px; height: 108px; text-indent: -9999px; position: relative;}
.main-sch__list__thumb:after {content: ''; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: #C20019; opacity: 0; -webkit-transition: all 0.3s; transition: all 0.3s;}
.main-sch__list__link:hover .main-sch__list__thumb:after {opacity: 0.15;}
.main-sch__list__text {display: table-cell; vertical-align: middle; padding: 0 20px;}
.main-sch__list__text h5 {font-size: 22px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; white-space: normal; line-height: 1.2; max-height: 2.4em; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.main-sch__list__text p {font-size: 18px; margin-top: 5px;}

.main-sch__list.res {border-bottom: solid 1px #000;}
.main-sch__list.res .main-sch__list__thumb {height: 180px;}
.main-sch__list__sel {display: table-cell; vertical-align: middle; width: 120px;}
.main-sch__list__sel button {display: block; width: 100%; background: #fff; border: solid 1px #000; height: 42px; line-height: 40px; text-align: center; font-size: 16px; -webkit-border-radius: 30px; border-radius: 30px; -webkit-transition: all 0.3s; transition: all 0.3s;}
.main-sch__list__sel button.on {background: #000; color: #fff;}

.res-bottom {margin-top: 0px;margin-bottom: 30px; }
.res-bottom:after {content: ''; display: table; clear: both;}
.res-info {float: left;}
.res-info li {position: relative; padding-left: 8px; font-size: 14px;}
.res-info li:before {content: ''; display: block; width: 3px; height: 3px; background: #000; -webkit-border-radius: 50%; border-radius: 50%; position: absolute; left: 0; top: 11px;}
.res-submit {margin: 10px ; display: block; float: right; height: 50px; line-height: 50px; background:#000; color: #fff; -webkit-border-radius: 30px; border-radius: 30px; padding: 0 30px; font-size: 20px; font-weight: 400; -webkit-transition: all 0.3s; transition: all 0.3s;}
.res-submit.on {background: #000;}



/* PreReservation End */ 


/* Main Popup Start */

.popup-wrapper {position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999;  }
.main-popup {position: relative; 
	         display : flex ; 
             flex-direction: column;
	         width:400px; height:500px; z-index: 1000; background: #fff;    border: solid 2px #000; top:10%; left: calc((100% - 400px)/2); background: url('../public/img/popup/idls.png')no-repeat center center / cover; }
.main-popup-close{ text-align: right; }
.main-popup-close button { font-size: 20px; font-weight: 700; }

.main-popup-body{ height: 300px;}
.main-popup-link{ height: 60px; display:flex; flex-direction: row;}
.main-popup-link-naver { width : 200px; height:100%; cursor:pointer; }
.main-popup-link-catchtable { width : 200px; cursor:pointer; }

.main-popup-gap{height : 90px; width: 100%;}
.main-popup-todayonly{  height:25px; }
.main-popup-todayonly button {  padding-left : 15px; color:white; background: #000;}
.main-popup-todayonly input { padding-top: 13px; left : 10px; border: solid 1px white;}

/* Main Popup End */



/* Main Artist Start */


.main-atst {}
.main-atst__row {border-top: solid 1px #000; border-bottom: solid 1px #000; position: relative; padding-right: 200px;}
.main-atst__menu {width: 200px; position: absolute; right: 0; top: 0;}
.main-atst__menu li {border-bottom: solid 1px #000;}
.main-atst__menu li button,
.main-atst__menu li a {display: block; height: 50px; line-height: 50px; width: 100%; text-align: left; background: none; font-size: 18px; font-weight: 700; position: relative; padding-left: 40px;}
.main-atst__menu li button:before {content: ""; display: block; width: 12px; height: 6px; position: absolute; left: 10px; top: 50%; margin-top: -3px; background: url('./img/main/main_artist_arrow.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
.main-atst__menu li button:hover:before,
.main-atst__menu li a:hover::before {left: 20px;}





.main-atst__load {}
.main-atst__list {}
.main-atst__list:after {content: ''; display: table; clear: both;}
.main-atst__list li {float: left; width: 25%; padding-right : 3px; }
.main-atst__pic {position: relative;}
.main-atst__pic em {display: block; padding-bottom: 84.21%; text-indent: -9999px; font-size: 0px;}
.main-atst__pic:after {content: ''; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: #C20019; opacity: 0; -webkit-transition: all 0.3s; transition: all 0.3s;}
.main-atst__list li:hover .main-atst__pic:after {opacity: 0.15;}
.main-atst__fav {display: block; width: 24px; height: 20px; position: absolute; right: 10px; bottom: 10px; text-indent: -9999px; z-index: 1; background: url('./img/common/artist_favorite.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
.main-atst__favon {display: block; width: 24px; height: 20px; position: absolute; right: 10px; bottom: 10px; text-indent: -9999px; z-index: 1; background: url('./img/common/artist_favorite_on.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}

.main-atst__link {display: block; padding: 20px;}
.main-atst__link span {display: block; font-size: 18px; font-family: 'Noto Serif', serif; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.main-atst__link h5 {font-size: 30px; line-height: 1.2; letter-spacing: -0.075em; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.main-atst__link p {margin-top: 10px; overflow: hidden; text-overflow: ellipsis; white-space: normal; line-height: 1.4; height: 2.8em; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}


 

.link-hover {position: relative;}
.link-hover:after {content: ''; display: block; width: 15px; height: 15px; position: absolute; right: 20px; top: 20px; background: url('./img/common/hover_arrow.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
.link-hover:hover:after {right: 10px; top: 10px;}

.main-photo__list {padding: 30px 0; position: relative; margin: 0 -3px;background: #000;}
.main-photo__list:before {content: ''; display: block; background: #000; position: absolute; top: 0; bottom: 0;}
.main-photo__list .slick-slide {padding: 0 3px;}
.main-photo__list .slick-slide img {display: block; width: 100%;}

/* Main Artist End */ 

/* ArtistList Start */

.atst-title {}
.atst-title:after {content: ''; display: table; clear: both;}
.atst-title .main-title {float: left;}
.atst-title__right {float: right; padding: 18px 15px 0;}
.atst-title__right:after {content: ''; display: table; clear: both;}

.atst-select {float: left; position: relative; margin-right: 20px; }
.atst-select button {display: block; position: relative; min-width: 100px; font-size: 15px; background: none; text-align: left;}
.atst-select button:after {content: ''; display: block; width: 13px; height: 7px; position: absolute; right: 0; top: 50%; margin-top: -4px; background: url('./img/common/select_arrow.png')no-repeat center bottom / 100% auto;}
.atst-select button.on:after {background-position: center top;}
.atst-select ul {display: block; position: absolute; z-index: 1; background: #fff; border: solid 1px #000; top: 100%; left: 50%; width: 300px; margin-left: -150px; padding: 23px 30px;}
.atst-select ul:after {content: ''; display: table; clear: both;}
.atst-select ul li {float: left; width: 50%; padding: 7px 20px; display: block; color: #8C8C8C; font-size: 14px;}
.atst-select ul li a.on {color: #000;}
.atst-select ul li:hover{cursor:pointer ; font-weight: 700; color :black} 

.atst-sort {float: left; cursor :pointer; }
.atst-sort:after {content: ''; display: table; clear: both;}
.atst-sort li {float: left; margin-right: 10px;}
.atst-sort li:last-child {margin-right: 0;}
.atst-sort li a {display: block; font-size: 13px; color: #aaa;}
.atst-sort li a.on {color: #000;}

/* ArtistList End */ 




/* Sub Start */ 
.list-more {margin-top: 50px;}
.list-more button,
.list-more a {display: block; width: 140px; height: 54px; line-height: 54px; background: #000; font-size: 16px; margin: 0 auto; color: #fff;}


/* sub common */
.sub-back {padding: 20px 0; border-bottom: solid 1px #000;}
.sub-back:after {content: ''; display: table; clear: both;}
.sub-back p {cursor: pointer ; display: block; width: 90px; float: left; height: 34px; line-height: 32px; text-align: center; border: solid 1px #000; -webkit-border-radius: 20px; border-radius: 20px;}
.sub-back p:before {content: ''; display: inline-block; vertical-align: middle; width: 15px; height: 8px; background: url('./img/common/btn_back.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
.paging {text-align: center; margin-top: 50px;}
.paging a {width: 30px; height: 30px; line-height: 30px; font-size: 18px; font-weight: 700; color: #9A9A9A;}
.paging > a {display: inline-block; vertical-align: middle; text-indent: -9999px; opacity: 0.4; -webkit-transition: all 0.3s; transition: all 0.3s;}
.paging > a:hover {opacity: 1;}
.paging > a.prev {background: url('./img/common/paging_prev.png')no-repeat center center / 10px auto;}
.paging > a.next {background: url('./img/common/paging_next.png')no-repeat center center / 10px auto;}
.paging ul {display: inline-block; vertical-align: middle; margin: 0 40px;}
.paging ul:after {content: ''; display: table; clear: both;}
.paging ul li {float: left; margin: 0 5px;}
.paging ul li a {display: block;}
.paging ul li a.on {color: #000;}

#sub {}
#sub-wrap {display: table; width: 100%; border-top: solid 1px #000;}
#sub-aside {display: table-cell; vertical-align: top; width: 200px;  border-right: solid 1px #000; }
#sub-aside ul {}
#sub-aside ul li {border-bottom: solid 1px #000;}
#sub-aside ul li  {display: block; font-size: 16px; font-weight: 700; color: #929292; position: relative; padding-left: 40px; height: 54px; line-height: 54px;}
#sub-aside ul li .on {color: #000;  }
#sub-aside ul li :before {content: ''; display: block; width: 20px; height: 9px; position: absolute; left: 0; top: 50%; margin-top: -5px; background: url('./img/sub/aside_arrow.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
#sub-aside ul li .on:before {background: url('./img/sub/aside_arrow_on.png')no-repeat center center / cover;}
#sub-aside ul li :hover:before {left: 10px; }
#sub-cont {display: table-cell; vertical-align: top;padding-bottom: 120px;}

.sub-cont__title {display: none;}



/* Sub End */ 



.clear {clear:both;}
.clear:after { content:""; display:block; clear:both;}

   .wrap{
    position:relative ; 
    display : flex ; 
    flex-direction: row;
    justify-content: center ;
    margin: auto ; 

   }

   .main
    {
      margin : 10px 10px 10px 10px ; 
      
    }

    .subtitle{
      font-size: 2.4rem; 
      color : #C20019 ; 
      
    }

    @media (min-width:900px) {
      .main {   width: 95%; max-width: 1024px;  } }
    @media (min-width:600px) {   
      .main {   width: 95%; max-width: 1024px;  }
      .subtitle { font-size : 3rem ; }
    }
    @media (max-width:600px) {   
      .main {   width: 98%; max-width: 600px; } }


/* Reservation */ 



.res-order__info {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 12px 0;}
.res-order__info:after {content: ''; display: table; clear: both;}
.res-order__info div{float:right; color : black; }


.res-order__info h5 {float: left; font-size: 18px; height: 20px; line-height: 20px;}
.res-order__info span {float: left; font-size: 16px; height: 20px; line-height: 20px; margin-left : 20px }
.res-order__info ul {float: right; font-size: 14px;}
.res-order__info ul:after {content: ''; display: table; clear: both;}
.res-order__info ul li {margin-right: 20px; float: left; height: 20px; line-height: 20px;}
.res-order__info ul li:last-child {margin-right: 0;}
.res-order__map {background: #f4f4f4;  display: table; width: 100%;}

.res-order__map--left {position : relative; height:550px; display: table-cell; width: 50%; border-right: solid 1px #d9d9d9;   }
.res-order__map--left img {margin: 0 auto; max-width: 100%;}
.res-order__map--left img.pc {display: block;}
.res-order__map--left img.mo {display: none;}

.res-order__map--cont{  position : relative; height : 100%; width : 380px;  margin:0px auto;  }

.res-order__map--right {display: table-cell; vertical-align: middle; width: 50%;}
.res-order__map--amount {border-bottom: solid 1px #d9d9d9; padding: 30px 50px;}
.res-order__map--amount dt {color: #666; font-size: 16px; margin-bottom: 10px;}
.res-order__map--amount dd {}
.res-order__map--amount dd:after {content: ''; display: table; clear: both;}
.res-order__map--amount dd h5 {float: left; font-size: 16px; color: #666; font-weight: 400;}
.res-order__map--amount dd h5 em {color: #000; margin-left: 5px;}
.res-order__map--amount dd h5:before {content: ''; display: inline-block; width: 14px; height: 14px; margin-right: 8px;}
.res-order__map--amount dd h5.zone-a:before {background: #d3d3d3;}
.res-order__map--amount dd h5.zone-b:before {background: #878787;}
.res-order__map--amount dd h5.zone-c:before {background: #333;}
.res-order__map--amount dd p {float: right; font-size: 14px; color: #666;}
.res-order__map--amount dd p b {font-weight: 500; font-size: 16px;}
.res-order__map--info {padding: 30px 50px;}
.res-order__map--info dt {color: #666; font-size: 16px; margin-bottom: 10px;}
.res-order__map--info dd {}
.res-order__map--info dd ul {}
.res-order__map--info dd ul li {font-size: 14px; color: #666; margin-bottom: 5px; position: relative; padding-left: 10px;}
.res-order__map--info dd ul li:last-child {margin-bottom: 0;}
.res-order__map--info dd ul li:before {content: ''; display: block; width: 3px; height: 3px; background: #666; position: absolute; left: 0; top: 9px; -webkit-border-radius: 50%; border-radius: 50%;}
.res-order__chkall {border-top: solid 1px #000; padding: 10px;}
.res-order__chkall input[type="checkbox"] + label {font-size: 16px; margin-left: 10px;}






.res-order__step {display: flex; flex-direction : row;  justify-content : space-between; width: 100%; padding: 15px 0; border-bottom: solid 1px #000;}
.res-order__step > div {  }
.res-order__step--person{text-align: center; width: 35%; display: flex; flex-direction : row; }
.res-order__step--person label {font-size: 16px; font-weight: 500; margin: auto  10px ; }
.res-order__step--person select {font-size: 13px; font-weight: 500; margin: auto 10px ; }
.res-order__step--concert {width: 50%;}
.res-order__step--list {}
.res-order__step--list li {margin-bottom: 10px;}
.res-order__step--list li:last-child {margin-bottom: 0;}
.res-order__step--box {position: relative; padding-left: 30px;}
.res-order__step--box img{position: absolute ; }
.res-order__step--box input[type="checkbox"] {position: absolute; left: 0; top: 50%; margin-top: -9px;}
.res-order__step--box input[type="checkbox"] ~ label {display: table; width: 100%; height: 100px; position: relative; padding-left: 120px; margin-left: 0;}
.res-order__step--box input[type="checkbox"] ~ label em {display: block; width: 100px; height: 100px; position: absolute; left: 0; top: 50%; margin-top: -50px;}
.res-order__step--box input[type="checkbox"] ~ label em:after {content: ''; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: #C20019; opacity: 0; -webkit-transition: all 0.3s; transition: all 0.3s;}
.res-order__step--box input[type="checkbox"]:checked ~ label em:after {opacity: 0.15;}
.res-order__step--image { width: 120px; height : 100px; object-fit:cover; }
.res-order__step--text {display: table-cell; width: 100%; vertical-align: middle; padding-left : 50px; }
.res-order__step--text span {font-size: 12px;}
.res-order__step--text h5 {font-size: 20px;}
.res-order__step--text p {font-size: 14px;}
.res-order__step--select {text-align: center; }
.res-order__step--select button {height: 40px; line-height: 38px; font-size: 16px; background: none; border: solid 1px #000; padding: 0 20px; -webkit-border-radius: 30px; border-radius: 30px;}
.res-order__step--select button:hover {background: #000; color: #fff;}


.res-order__add {table-layout: auto; border-top: solid 1px #000;}
.res-order__add th,
.res-order__add td {border-bottom: solid 1px #000; padding: 15px 0;}
.res-order__add th img {display: block; width: 160px; height: 150px; object-fit : cover ;  }
.res-order__add td {font-size: 16px;}
.res-order__add button {display: inline-block; background: none; border: solid 1px #000; height: 34px; line-height: 32px; padding: 0 15px; font-size: 14px;}

.res-order__fixed {position: fixed; left: 0; right: 0; bottom: 0; z-index: 99; background: #fff; border-top: solid 1px #000;}
.res-order__fixed .inner {width: 100%; max-width: 1440px; padding: 0 50px; margin: 0 auto;}
.res-order__fixed .inner:after {content: ''; display: table; clear: both;}
.res-order__fixed .res-order__total {float: left; border-top: none; border-bottom: none;}
.res-order__fixed .res-order__total p {height: 50px; line-height: 50px;}
.res-order__fixed .res-order__submit {float: right;}

.res-order__submit {padding: 20px 0;}
.res-order__submit:after {content: ''; display: table; clear: both;}
.res-order__submit li {float: right; margin-left: 10px;}
.res-order__submit a,
.res-order__submit button {display: block; text-align: center; width: 160px; height: 50px; line-height: 48px; font-size: 18px; border: solid 1px #000; background: none; -webkit-border-radius: 30px; border-radius: 30px;}
.res-order__submit a.bk,
.res-order__submit button.bk {background: #000; color: #fff;}

.res-order__del p {font-size: 14px; color: #666;}
.res-order__total {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 20px 0; text-align: right;}
.res-order__total p {font-size: 18px; font-weight: 700; line-height: 1.0;}
.res-order__total p span {font-size: 24px; font-weight: 700; margin-left: 10px;}

/* Reservation */ 
.res-order__report {width: 50%;}
.res-order__sel {width: 50%;}
.res-order__map {padding: 10px;}
.res-order__map--left {padding: 0 10px;}
.res-order__map--amount {padding: 20px 30px;}
.res-order__map--info {padding: 20px 30px;}
.res-order__thumb {width: 200px;}
.res-order__text {padding-left: 20px;}
.res-order__text span {font-size: 18px;}
.res-order__text h5 {margin-top: 10px; font-size: 16px;}
.res-order__text p {font-size: 14px;}
.res-order__sel dl dt {margin-bottom: 10px;}
.res-order__sel dl dd {padding: 5px 30px;}
.res-order__sel dl dd select {width: 60px;}
.res-order__sel dl dd p {width: 80px;}
.res-order__amount {padding: 5px 20px; margin-top: 10px;}
.res-order__time {margin-top: 10px;}


/* Reservation End */ 
      


/* Artist Detail Start */



.atst-detail {font-size: 14px;}
.atst-detail td {border-left: solid 1px #000; border-bottom: solid 1px #000; padding: 10px 30px;}
.atst-detail th.atst-detail__pic {border-bottom: solid 1px #000; width: 320px;}
.atst-detail th.atst-detail__pic em {display: block; padding-bottom: 100%;}
.atst-detail td.atst-detail__name {width : 100%; }
.atst-detail td.atst-detail__name strong {font-size: 30px; margin-right: 5px;}
.atst-detail td.atst-detail__name span {font-family: 'Noto Serif', serif; font-size: 20px;}
.atst-detail td.atst-detail__fav {width: 140px;}
.atst-detail td.atst-detail__fav button {display: block; width: 100%;  text-align: center; font-size: 16px; background: none;}
.atst-detail td.atst-detail__fav button:before {content: ''; display: inline-block; vertical-align: middle; width: 24px; height: 20px; margin-right: 20px; text-indent: -9999px; background: url('./img/common/artist_favorite_bk.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
.atst-detail td.atst-detail__fav.on button:before {background: url('./img/common/artist_favorite_bk_on.png')no-repeat center center / cover;}
.atst-detail td.atst-detail__text {word-break: keep-all; }
.atst-detail td.atst-detail__text h5 {font-size: 20px;}
.atst-detail td.atst-detail__text p {margin-top: 10px;}
.atst-detail td.atst-detail__part {}
.atst-detail__slider {}
.atst-detail__slider .slick-arrow {display: block; width: 30px; height: 30px; position: absolute; top: 50%; margin-top: -15px; z-index: 1; border: solid 1px #000; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px;}
.atst-detail__slider .slick-prev {left: 10px; background: url('./img/common/paging_prev.png')no-repeat center center / 8px auto;}
.atst-detail__slider .slick-next {right: 10px; background: url('./img/common/paging_next.png')no-repeat center center / 8px auto;}


.mpg-team {padding: 0 50px; max-width: 520px;}
.mpg-team__profile {position: relative; text-align: center; margin-bottom: 20px;}
.mpg-team__profile p {font-size: 14px; font-weight: 700; margin-top: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.mpg-team__profile span {display: block; font-size: 14px;}
.mpg-team__profile--leader {display: block; width: 22px; height: 22px; background: #fff url('./img/sub/team-profile_leader.png')no-repeat center center / 12px auto; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px; position: absolute; right: 0; bottom: 0;}
.mpg-team__profile--setting {position: absolute; right: 0; bottom: 0;}
.mpg-team__profile--setting > button {display: block; width: 22px; height: 22px; background: #fff url('./img/sub/team-profile_setting.png')no-repeat 5px center / 11px auto; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px;}
.mpg-team__profile--setting ul {display: none; position: absolute; border: solid 1px #000; background: #fff; width: 120px; z-index: 2; left: 100%; top: 0; margin-left: 5px;}
.mpg-team__profile--setting ul li {border-bottom: solid 1px #000;}
.mpg-team__profile--setting ul li:last-child {border-bottom: none;}
.mpg-team__profile--setting ul li a,
.mpg-team__profile--setting ul li button {display: block; width: 100%; background: none; text-align: left; padding: 5px 10px; font-size: 13px;}
.mpg-team__profile--setting ul li a:hover,
.mpg-team__profile--setting ul li button:hover {background: #f4f4f4;}
.mpg-team__profile--setting ul {left: auto; right: 100%; margin-right: 5px;}
.mpg-team__profile--add {display: block; width: 24px; height: 24px; background: #fff; z-index: 1; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px; position: absolute; right: 0; bottom: 0;}
.mpg-team__profile--add:before,
.mpg-team__profile--add:after {content: ''; display: block; background: #d9d9d9; position: absolute; left: 50%; top: 50%;}
.mpg-team__profile--add:before {width: 12px; height: 2px; margin-left: -6px; margin-top: -1px;}
.mpg-team__profile--add:after {width: 2px; height: 12px; margin-left: -1px; margin-top: -6px;}

.join-artist__img {overflow-x: auto;  margin: 0 -3px; max-width: 100%;  }
.join-artist__img.mpg {max-width: 100%;}
.join-artist__img:after {content: ''; display: table; clear: both;}
.join-artist__img > li {float: left; width: 25%; padding: 0 3px; position: relative;}
.join-artist__img.atst > li {width: 106px; margin-right: 10px;}
.join-artist__img--thumb {display: block; padding-bottom: 100%;}
.join-artist__img--delete {display: block; text-indent: -9999px; width: 28px; height: 28px; background: rgba(0,0,0,0.5); position: absolute; right: 3px; top: 0;}
.join-artist__img--delete:before,
.join-artist__img--delete:after {content: ''; display: block; background: #fff; position: absolute; left: 50%; top: 50%; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
.join-artist__img--delete:before {width: 19px; height: 1px; margin-left: -9px;}
.join-artist__img--delete:after {width: 1px; height: 19px; margin-top: -9px;}
.join-artist__img--add {display: block; width: 100%; padding-bottom: 100%; text-indent: -9999px; font-size: 0px; background: #d9d9d9; position: relative;}
.join-artist__img--add:before,
.join-artist__img--add:after {content: ''; display: block; background: #fff; position: absolute; left: 50%; top: 50%;}
.join-artist__img--add:before {width: 24px; height: 2px; margin-left: -12px; margin-top: -1px;}
.join-artist__img--add:after {width: 2px; height: 24px; margin-left: -1px; margin-top: -12px;}
.join-profile {position: relative;}
.join-profile em {display: block; padding-bottom: 100%; -webkit-border-radius: 50%; border-radius: 50%;}
.join-profile button {display: block; width: 24px; height: 24px; background: #fff; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px; position: absolute; right: 0; bottom: 0;}
.join-profile button:before,
.join-profile button:after {content: ''; display: block; background: #d9d9d9; position: absolute; left: 50%; top: 50%;}
.join-profile button:before {width: 12px; height: 2px; margin-left: -6px; margin-top: -1px;}
.join-profile button:after {width: 2px; height: 12px; margin-left: -1px; margin-top: -6px;}

.slick-prev {
	
	left: 3% !important;
	z-index: 1;
  }
  .slick-next {
	right: 3% !important;
	z-index: 1;
  }

  .atst-video {margin: 0 -10px;  display: flex; flex-direction: row; justify-content: space-between;}
  .atst-video .slick-slide {padding: 0 10px;}
  .atst-video__title:after {content: ''; display: table; clear: both;}
  .atst-video__title .main-title {float: left;}
  .atst-video__arrows {float: right; padding-top: 20px;}
  .atst-video__arrows:after {content: ''; display: table; clear: both;}
  .atst-video__arrows .slick-arrow {display: block; width: 30px; height: 30px; float: left; border: solid 1px #000; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px;}
  .slick-prev {margin-right: 8px; background: url('./img/common/paging_prev.png')no-repeat center center / 8px auto;}
  .atst-video__arrows .slick-next {background: url('./img/common/paging_next.png')no-repeat center center / 8px auto;}
  .atst-video__frame {width: 100%; height: 100%; }
  .atst-video__frame iframe {width: 300px; height: 200px;}
  
  .atst-pfm td {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 10px 0; text-align: center; font-size: 16px;}
  .atst-pfm td.atst-pfm__btn button {display: inline-block; padding: 0 15px; height: 34px; line-height: 32px; border: solid 1px #000; -webkit-border-radius: 30px; border-radius: 30px; font-size: 16px; background: #fff;}
  .atst-pfm td.atst-pfm__btn button.on {background: #000; color: #fff;}



.atst-inst {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 15px 0 10px;}
.atst-inst:after {content: ''; display: table; clear: both;}
.atst-inst li {float: left; margin-right: 5px; margin-bottom: 5px;}
.atst-inst li span {display: block; font-size: 15px; padding: 0 15px; height: 34px; line-height: 34px; background: #000; color: #fff; -webkit-border-radius: 30px; border-radius: 30px;}


/* Artist Detail End */ 

/* Login Start */


/* login/Join */
#login-container{ display:flex; flex-direction: column;justify-content: space-between; padding-left: 50px; padding-right: 50px;}
#login {display: table-cell; width: 100%; vertical-align: middle; height: 78vh; display:flex; flex-direction: column; justify-content: center; }
.login-box {width: 100%; max-width: 480px; margin: 0 auto;}
.login-title {width: 334px; height: 60px; text-indent: -9999px; margin: 0 auto; background: url('./img/sub/login_logo.png')no-repeat center bottom / 100% auto;}
.login-cont {margin-top: 50px;}
.login-cont dt {text-align: center; font-size: 18px; font-weight: 700;}
.login-cont dd {margin-top: 20px;}
.login-cont dd ul{text-align: center;}
.login-cont dd ul li {display: inline-block; margin: 0 10px;}
.login-cont dd ul li a,
.login-cont dd ul li button {display: block; width: 44px; height: 44px; text-indent: -9999px; -webkit-border-radius: 50%; border-radius: 50%;}
.login-cont dd ul li .login-kakao {background: url('./img/sub/login_sns_kakao.png')no-repeat center center / cover;}
.login-cont dd ul li .login-naver {background: url('./img/sub/login_sns_naver.png')no-repeat center center / cover;}
.login-cont dd ul li .login-google {background: url('./img/sub/login_sns_google.png')no-repeat center center / cover; }
.login-join {margin-top: 35px; padding-top: 20px; border-top: solid 1px #ebebeb;}
.login-join a {display: block; height: 54px; line-height: 52px; border: solid 1px #acacac; text-align: center; font-size: 16px; font-weight: 700;}
.login-join p {color: #5e5e5e; text-align: center; font-size: 12px;}
.login-join p br {display: none;}
.login-text {text-align: center; margin-top: 30px;}
.login-text span {display: inline-block; background: #000; color: #fff; font-size: 13px; padding: 2px 6px; -webkit-border-radius: 4px; border-radius: 4px;}
.join-sel {margin: 0 -5px; padding-top: 30px; padding-bottom: 20px;}
.join-sel:after {content: ''; display: table; clear: both;}
.join-sel li {float: left; width: 50%; padding: 0 5px;}
.join-sel li {display: block; width: 48%; margin-left: 2%; font-size: 14px; height: 54px; line-height: 52px; border: solid 1px #acacac; font-weight: 700; text-align: center;}
.join-sel li button:hover,
.join-sel li:hover {background: #000; border-color: #000; color: #fff; -webkit-transition: all 0.3s; transition: all 0.3s;}


/* Login End */ 


/* Order Start */ 

.toast-alert {

	background-color: rgba(255, 127, 80, 0.4);
	border: 1px solid rgba(255, 127, 80, 0.1);
	border-radius: 10px;
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
	height: 40px;
	width: 250px;
	padding: 5px;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	color: black;
	position: fixed;
	z-index: 99;
	bottom: 70px;
	left: 0px;
	right: 0px; 
	margin: 0px auto; 
  }



/* order */
#order { margin : 10px;  width:100%; }

.order-top {position:fixed; left:10px; right:10px; display: flex; flex-direction: column; justify-content: space-between; background: #fff;z-index: 95;}

.sly {  }

.sly > li {float: left; margin:8px; }
.order-cate {border-top: solid 1px #000; border-bottom: solid 1px #000;  cursor:pointer; margin-top:10px}
.order-cate ul {}
.order-cate ul li {margin-right: 10px;}

.order-menu {display: flex;  flex-direction: row; justify-content: space-between;  vertical-align: middle; }
.order-title { font-size: 30px; color: #C20019;  font-weight: 700; text-align: left;  line-height: 1;}
.order-table {font-size: 12px; font-weight: 700;  margin : 4px;  }
.order-menu button { background: black; color: #fff; height : 30px; width:60px; }

.order-back p {cursor: pointer ; width: 70px; height: 25px; margin-right:20px; line-height: 22px; text-align: center; border: solid 1px #000; -webkit-border-radius: 20px; border-radius: 20px;}
.order-back p:before {content: ''; display: inline-block; vertical-align: middle; width: 15px; height: 8px; background: url('./img/common/btn_back.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}


.order-list { height:calc(100vh - 270px);   position:relative; overflow-y:scroll; overflow-x: hidden; border-top: solid 1px #000;}
.cart-list { height:calc(100vh - 150px); margin-top:10px; position:relative; overflow-y:scroll; overflow-x: hidden; border-top: solid 1px #000;}
.order-list__box {border-bottom: solid 1px #d9d9d9; padding: 15px 0; }
.order-list__inner {position: relative; display:flex; flex-direction: row; justify-content:space-between ;  height:100px;  }
.order-list__thumb { position : relative; width: 110px; height: 100px; object-fit: cover;}
.order-list__text { position : relative; width:calc(94% - 110px - 50px);  }
.order-list__text.ordered { position : relative; width:calc(94% - 110px);  }
.order-list__text.on{ position : relative; width:calc(94% - 50px);  }
.order-list__text h5 {font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;   }
.order-list__text p {font-size: 12px; margin-top: 5px; color: #777; overflow: hidden; text-overflow: ellipsis; white-space: wrap; line-height: 1.4; height: 2.8em; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
.order-list__text h2 {font-size: 12px; color: #C20019; line-height: 1.4; height: 2.8em; font-weight: 400;}

.order-list__cart { position : relative; width : 40px; height : 50px; margin:10px; background : black; color : white; font-size : 12px; border-radius: 10px; }
.order-list__cart div { display: flex; flex-direction: column; justify-content: space-between;  }
.order-list__cart span { position : relative ; font-size : 20px; margin: -5px; }
.order-list__cart p { position : relative ; font-size : 12px; margin: -5px; }


.order-list__text ul {  position:relative;  display : flex ;  flex-direction : row; justify-content : space-between ;  width:100% ; font-size : 15px;  margin-top: 10px; } 
.order-list__btn { display : flex ; flex-direction:row; justify-content: space-between; border : solid 1px  #dde0e3 ; width : 80px ; height : 27px; font-size : 13px; line-height : 25px;text-align: center;    }
.order-list__btn p { height : 25px; width: 30px ;  }
.order-list__btn button { width : 25px ; height : 25px;  }
.order-list__price { position: relative;  }
.order-list__trashcan { font-size:20px; position: relative; line-height: 100px; width:30px; cursor: pointer; background-color: white; color:grey;}

.order-list__winedtl { display:inline-block; }
.order-list__winedtl button { display:inline-block; background-color: gray; color: white; cursor:pointer ; padding: 4px; margin : 0px 5px;}

.order-list_btm { display: flex; flex-direction: row; justify-content: space-between;}
.order-list_btm h4{ font-size : 18px; background-color: white; }
.order-list_btm span{ font-size : 16px; background-color: white; }

.order-bottom {position: fixed; left: 10px; right: 10px; bottom: 0; background: #fff; border-top: solid 1px #000; z-index: 95; padding: 10px 0; }
.order-bottom__info {margin-bottom: 10px;}
.order-bottom__info:after {content: ''; display: table; clear: both;}
.order-bottom__info h5 {height: 30px; line-height: 30px; float: left; font-weight: 400; font-size: 16px;}
.order-bottom__info input {font-weight: 300; width : 60% ;}
.order-bottom__info button {display: block; float: right; background: none; width: 90px; height: 30px; line-height: 28px; border: solid 1px #000; font-size: 14px; -webkit-border-radius: 30px; border-radius: 30px;}
.order-bottom__info button.on {background: #000; color: #fff;}

.order-cart {display: block; width: 100%; position: relative; height: 46px; background: #000; line-height: 46px; color: #fff; font-size: 16px; font-weight: 500; text-align: center;}
.order-cart.disabled {background: #d9d9d9;}
.order-cart__count {display: block; width: 20px; height: 20px; line-height: 20px; text-align: center; background: #fff; color: #000; -webkit-border-radius: 50%; border-radius: 50%; position: absolute; left: 20px; top: 50%; margin-top: -10px; font-size: 12px;}
.order-cart__total {height: 20px; line-height: 20px; font-size: 12px; position: absolute; right: 20px; top: 50%; margin-top: -10px; font-weight: 300;}
.order-cart__total b {font-weight: 500; font-size: 14px; margin-right: 3px;}
.order-cart span{ font-size : 15px;  }
.order-cart h3{ font-size : 18px; font-weight: 300;  }

.order-add {position: fixed; left: 0; right: 0; bottom: -100%; background: #fff; z-index: 110; padding: 15px 20px;}
.order-add__info {display: table; width: 100%; padding: 5px 0 20px;}
.order-add__name {display: table-cell; vertical-align: middle; font-size: 16px; font-weight: 700;}
.order-add__count {display: table-cell; vertical-align: middle; width: 60px;}
.order-add__count select {display: block; width: 100%;}
.order-add__amount {display: table-cell; vertical-align: middle; width: 80px; text-align: right; font-size: 14px;}
.order-add__amount b {font-weight: 700; font-size: 16px;}
.order-add__bg {display: none; position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 105; background: rgba(0,0,0,0.5);}
.order-add .order-dtl__wine {margin-top: 0; margin-bottom: 20px;}


.order-dtl__thumb {}
.order-dtl__thumb img {display: block; margin: 0 auto; max-width: 100%;}
.order-dtl__info {margin-top: 30px;}
.order-dtl__info h5 {font-size: 24px;}
.order-dtl__info p {font-size: 14px; color: #727272; margin-top: 5px;}
.order-dtl__pasta {margin-top: 30px;}
.order-dtl__pasta li {border-bottom: solid 1px #d9d9d9; padding: 15px 0;}
.order-dtl__pasta li:after {content: ''; display: table; clear: both;}
.order-dtl__pasta li:last-child {border-bottom-color: #000;}
.order-dtl__pasta li h5 {float: left; font-size: 18px;}
.order-dtl__pasta li p {float: right; font-size: 16px;}
.order-dtl__pasta li p b {font-size: 20px; font-weight: 700; margin-right: 5px;}
.order-dtl__pasta li select {display: block; width: 80px; float: right;}
.order-dtl__add {display: block; width: 100%; margin-top: 30px; height: 46px; background: #000; line-height: 46px; color: #fff; font-size: 16px; font-weight: 500; text-align: center;}

.order-dtl__wine {margin-top: 30px;}
.order-dtl__wine dt {font-size: 18px; font-weight: 700; padding-bottom: 10px; border-bottom: solid 1px #000;}
.order-dtl__wine dd {border-bottom: solid 1px #d9d9d9; padding: 15px 0;}
.order-dtl__wine dd:last-child {margin-bottom: 0;}
.order-dtl__wine dd:after {content: ''; display: table; clear: both;}
.order-dtl__wine dd input[type="radio"] {display: block; float: left;}
.order-dtl__wine dd input[type="radio"] + label {display: block; float: left; height: 18px; line-height: 18px; font-size: 16px; font-weight: 700;}
.order-dtl__wine dd p {float: right; font-size: 14px; height: 18px; line-height: 18px;}
.order-dtl__wine dd p b {font-size: 18px; font-weight: 700; margin-right: 5px;}

.order-dtl__lv {padding: 20px 10px;}
.order-dtl__lv:after {content: ''; display: table; clear: both;}
.order-dtl__lv li {float: left; width: 50%; padding: 5px 0;}
.order-dtl__lv li:after {content: ''; display: table; clear: both;}
.order-dtl__lv li h5 {float: left; height: 14px; line-height: 14px; font-size: 14px; color: #727272; font-weight: 500; margin-right: 10px;}
.order-dtl__lv li span {float: left; display: block; width: 14px; height: 14px; margin-right: 5px; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%;}
.order-dtl__lv li span:last-child {margin-right: 0;}
.order-dtl__lv li.lv1 span:nth-child(-n+2) {background: #C20019; border-color: #C20019;}
.order-dtl__lv li.lv2 span:nth-child(-n+3) {background: #C20019; border-color: #C20019;}
.order-dtl__lv li.lv3 span:nth-child(-n+4) {background: #C20019; border-color: #C20019;}
.order-dtl__lv li.lv4 span:nth-child(-n+5) {background: #C20019; border-color: #C20019;}
.order-dtl__lv li.lv5 span:nth-child(-n+6) {background: #C20019; border-color: #C20019;}

.order-dtl__toggle {border-top: solid 1px #000; border-bottom: solid 1px #000;}
.order-dtl__toggle button {display: block; width: 100%; position: relative; height: 40px; background: none; font-size: 16px; text-align: left;}
.order-dtl__toggle button:after {content: ''; display: block; width: 17px; height: 9px; position: absolute; right: 10px; top: 50%; margin-top: -5px; background: url('./img/common/toggle_arrow.png')no-repeat center bottom / 100% auto;}
.order-dtl__toggle button.on:after {background-position: center top;}
.order-dtl__toggle > div {border-top: solid 1px #d9d9d9; padding: 10px 0; display: none;}
.order-dtl__toggle th,
.order-dtl__toggle td {padding: 5px 0; font-size: 14px; text-align: left;}
.order-dtl__toggle th {width: 90px; font-weight: 700;}

.order-bsk__text {min-height: 100px;}
.order-bsk__text h5 {font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.order-bsk__text span {font-size: 12px; color: #727272;}
.order-bsk__text p {font-size: 14px; margin-top: 10px;}
.order-bsk__text p b {font-size: 16px; font-weight: 700; margin-right: 5px;}
.order-bsk__state {display: block; position: absolute; right: 0; bottom: 0; height: 20px; line-height: 18px; padding: 0 5px; border: solid 1px #000;}
.order-bsk__state.cancel {background: #000; color: #fff;}

.order-bsk__option {margin-top: 10px;}
.order-bsk__option:after {content: ''; display: table; clear: both;}
.order-bsk__option select {display: block; float: right; height: 30px; width: 60px;}
.order-bsk__change {display: block; float: right; height: 30px; line-height: 28px; background: none; border: solid 1px #d9d9d9; padding: 0 10px; margin-right: 10px;}

.order-bsk__delete {display: block; width: 20px; height: 20px; background: none; position: absolute; right: 10px; top: 0; text-indent: -9999px;}
.order-bsk__delete:before,
.order-bsk__delete:after {content: ''; display: block; background: #000; position: absolute; left: 50%; top: 50%; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
.order-bsk__delete:before {width: 21px; height: 1px; margin-left: -10px;}
.order-bsk__delete:after {width: 1px; height: 21px; margin-top: -10px;}

.order-pay__total {padding: 15px 0;}
.order-pay__total:after {content: ''; display: table; clear: both;}
.order-pay__total h5 {float: left; font-size: 18px; font-weight: 400;}
.order-pay__total p {float: right; font-size: 16px;}
.order-pay__total p b {font-size: 20px; font-weight: 700; margin-right: 5px;}
.order-pay__cancel {border-top: solid 1px #000;}
.order-pay__cancel dt {padding: 10px 0; font-size: 16px; font-weight: 400;}
.order-pay__cancel dd {border-top: solid 1px #d9d9d9; background: #f4f4f4; padding: 10px 15px;}
.order-pay__cancel dd ul li {font-size: 12px; position: relative; padding-left: 6px;}
.order-pay__cancel dd ul li:before {content: ''; display: block; width: 2px; height: 2px; background: #666; -webkit-border-radius: 50%; border-radius: 50%; position: absolute; left: 0; top: 8px;}

.order-msg {border-color: #000;}

.order-wine_redcircle { display:inline-block; border : solid 1px #C20019; border-radius: 50%; width:13px; height:13px; background:#C20019; margin : 4px 1px;  }
.order-wine_whitecircle { display:inline-block; border : solid 1px #C20019; border-radius: 50%; width:13px; height:13px; background:white;  margin : 4px 1px;  }

.order-wine_wrap { position: absolute; top: 0; left: 0; right: 0; bottom:0 ;  z-index: 99;  background-color: rgba(0, 0, 0, 0.6); }
.order-wine_cont { position : fixed ; width : 90% ; height : 96vh ; left : 0 ; right : 0; margin : 0 auto ; top : 10px ;   background-color : #F4F4F4; display : flex; flex-direction: column; padding : 15px; }
.order-wine_cont h4{ font-size : 20px; text-align: right; cursor:pointer; } 
.order-wine_cont h1{ font-size : 40px; text-align: center; width : 100%; height: 50%; margin: 20px 0px;  } 
.order-wine_cont img{ width : 100%; height: 300px; object-fit: cover; margin : 10px auto;  }
.order-wine_title { height : 200px; }
.order-wine_title h2{font-size : 18px; }
.order-wine_title h3{font-size : 11px; font-weight: 300; color: gray; }

.order-wine_price { display: flex ; flex-direction: row ; justify-content: space-between; } 
.order-wine_price h2{ font-size:14px; margin-bottom: 10px; }

.order-wine_spec{ border-top : solid 2px #e5e5e5 ; padding : 10px 0px; }
.order-wine_spec ul{display:flex; flex-direction: row; justify-content: space-between;}


.order-wine_desc{ border-top : solid 1px grey; padding : 10px 0px;  }
.order-wine_desc ul{display:flex; flex-direction: row; justify-content: space-between;font-size : 12px; font-weight: 300; }
.order-wine_label { font-weight: 700;} 


.order-wine_cart{display: flex; flex-direction: row; justify-content :center; width:100%; height:40px;  background-color: black; color:white; font-size: 15px; line-height:40px;}

.order-wine_cart span{ margin-right : 10px; }
/* Order End */ 





/* Footer */
#footer {border-top: solid 1px #000;   position : relative; display: block;  float: bottom; margin-top:20px ; padding-top : 10px;}
#footer:after {content: ''; display: table; clear: both;}
.ft-logo {display: block; float: left; margin-right: 50px; text-indent: -9999px; width: 63px; height: 85px; background: url('./img/common/footer_logo.png')no-repeat center center / cover;}
.ft-menu {float: left; width: auto;}
.ft-menu th,
.ft-menu td {padding-top: 5px; padding-bottom: 5px;}
.ft-menu th {width: 80px; text-align: left; font-weight: 700;}
.ft-menu td {padding-right: 50px;}
.ft-menu tr td:last-child {padding-right: 0;}
.ft-menu ul {}
.ft-menu ul:after {content: ''; display: table; clear: both;}
.ft-menu ul li {float: left; padding-right: 10px; margin-right: 10px; position: relative;}
.ft-menu ul li:last-child {padding-right: 0; margin-right: 0;}
.ft-menu ul li:after {content: ''; display: block; width: 1px; height: 10px; background: #000; position: absolute; right: 0; top: 50%; margin-top: -4px;}
.ft-menu ul li:last-child:after {display: none;}
.ft-menu ul li a {display: block;}
.ft-info {display: none;}
.ft-other {float: right; margin-top: 5px; padding-bottom: 50px; position: relative;}
.ft-other__sns {position: absolute; left: 0; right: 0; bottom: 0; text-align: center;}
.ft-other__sns li {display: inline-block; vertical-align: middle; margin-right: 25px;}
.ft-other__sns li:last-child {margin-right: 0;}
.ft-other__sns li a {display: block; text-indent: -9999px;}
.ft-other__sns li a.ft-other__sns--fb {width: 13px; height: 25px; background: url('./img/common/footer_sns_facebook.png')no-repeat center center / cover;}
.ft-other__sns li a.ft-other__sns--ist {width: 25px; height: 25px; background: url('./img/common/footer_sns_instagram.png')no-repeat center center / cover;}
.ft-other__sns li a.ft-other__sns--ytb {width: 30px; height: 24px; background: url('./img/common/footer_sns_youtube.png')no-repeat center center / cover;}
.ft-other__privacy:after {content: ''; display: table; clear: both;}
.ft-other__privacy li {float: left; margin-right: 20px;}
.ft-other__privacy li:last-child {margin-right: 0;}
.ft-other__privacy li a {display: block; font-weight: 700;}

/* popup 시작 */ 



/* popup */
#pop-bg {position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999; background: rgba(0,0,0,0.2);}
#popup {position: fixed; top: 50%; left: 50%; z-index: 1000; background: #fff; width: 90%; text-align: center; padding: 40px 30px; border: solid 2px #000; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%);}
#popup.artist_favorite {max-width: 360px;}
#popup.reservation_check_step1 {max-width: 420px; padding-left: 50px; padding-right: 50px;}
#popup.reservation_check_step2 {max-width: 420px; padding-left: 50px; padding-right: 50px;}
#popup.reservation_payment {max-width: 360px;}
#popup.reservation_complete {max-width: 360px;}
#popup.join_warnning {max-width: 360px;}
#popup.join_finish {max-width: 360px;}
#popup.join_good {max-width: 360px;}
#popup.mypage_good {max-width: 360px;}
#popup.mypage_cancel {max-width: 360px;}
#popup.reservation_warnning {max-width: 420px;}
#popup.team_out {max-width: 360px;}
#popup.menu_warnning {max-width: 420px;}
#popup.menu_message {max-width: 420px;}


.pop-title {text-align: center;}
.pop-title h3 {font-size: 20px;}
.pop-title h4 {font-size: 26px;}
.pop-title h4:before {content: ''; display: block; margin: 0 auto 10px;}
.pop-title h4.pop-title__fav:before {width: 52px; height: 42px; background: url('./img/popup/popup_favorite.png')no-repeat center center / cover;}
.pop-title h4.pop-title__check1:before {width: 52px; height: 52px; background: url('./img/popup/popup_check1.png')no-repeat center center / cover;}
.pop-title h4.pop-title__check2:before {width: 53px; height: 53px; background: url('./img/popup/popup_check2.png')no-repeat center center / cover;}
.pop-title h4.pop-title__payment:before {width: 53px; height: 52px; background: url('./img/popup/popup_payment.png')no-repeat center center / cover;}
.pop-title h4.pop-title__finish:before {width: 53px; height: 53px; background: url('./img/popup/popup_finish.png')no-repeat center center / cover;}
.pop-title h4.pop-title__good:before {width: 44px; height: 44px; background: url('./img/popup/popup_good.png')no-repeat center center / cover;}
.pop-title h4.pop-title__edit:before {width: 42px; height: 42px; background: url('./img/popup/popup_edit.png')no-repeat center center / cover;}
.pop-title p {font-size: 14px;}
.pop-title span {font-size: 11px; color: #666; display: block;}
.pop-title span:before {content: ''; display: block; width: 200px; height: 1px; background: #9e9e9e; margin: 10px auto;}
.pop-btn {margin-top: 40px; text-align: center;}
.pop-btn li {display: inline-block; margin-right: 10px;}
.pop-btn li:last-child {margin-right: 0;}
.pop-btn li a,
.pop-btn li button {display: block; width: 120px; height: 40px; line-height: 38px; font-size: 14px; border: solid 1px #000; background: #fff; -webkit-border-radius: 30px; border-radius: 30px;}
.pop-btn li a.bk,
.pop-btn li button.bk {background: #000; border-color: #000; color: #fff;}
.pop-cookie {margin-top: 20px;}
.pop-cookie input[type="checkbox"] {}
.pop-cookie input[type="checkbox"] + label {font-size: 11px; color: #666;}
.pop-desc {text-align: left; line-height: 1.4; margin-top: 20px;}
.pop-desc li {font-size: 12px; color: #666; position: relative; padding-left: 6px; margin-bottom: 3px;}
.pop-desc li:last-child {margin-bottom: 0;}
.pop-desc li:before {content: ''; display: block; width: 2px; height: 2px; background: #666; -webkit-border-radius: 50%; border-radius: 50%; position: absolute; left: 0; top: 6px;}
.pop-chk__info {margin-top: 20px; border-top: solid 1px #9e9e9e;}
.pop-chk__info li {border-bottom: solid 1px #9e9e9e; padding: 10px 0;}
.pop-chk__info li dl {font-size: 14px;}
.pop-chk__info li dl dt {}
.pop-chk__info li dl dd {display: inline-block; vertical-align: middle; padding: 0 10px 0 7px; position: relative;}
.pop-chk__info li dl dd:first-child {padding-left: 0;}
.pop-chk__info li dl dd:last-child {padding-right: auto;}
.pop-chk__info li dl dd:after {content: ''; display: block; width: 1px; height: 10px; background: #666; position: absolute; right: 0; top: 50%; margin-top: -5px;}
.pop-chk__info li dl dd:last-child:after {display: none;}
.pop-warning {font-size: 12px; color: #666; padding-top: 10px; margin-top: 15px; border-top: solid 1px #9e9e9e; text-align: left; margin-bottom: -15px;}
.pop-chk__list {text-align: left; margin-top: 20px;}
.pop-chk__list li {position: relative; padding-left: 25px; margin-bottom: 5px;}
.pop-chk__list li:last-child {margin-bottom: 0;}
.pop-chk__list li input[type="checkbox"] {width: 20px; height: 20px; background-size: 12px auto !important; position: absolute; left: 0; top: 0;}
.pop-chk__list li input[type="checkbox"] + label {}



/* popup 끝 */ 




/* shop */
#shop {}
.shop-list {border-top: solid 1px #000;}
.shop-list:after {content: ''; display: table; clear: both;}
.shop-list li {float: left; width: 25%; border-bottom: solid 1px #000; border-right: solid 1px #000;}
.shop-list li:nth-child(4n) {border-right: none;}
.shop-list li a {display: block;}
.shop-list li a em {display: block; padding-bottom: 81.0447%;}
.shop-list li a dl {padding: 15px; font-size: 20px;}
.shop-list li a dl:after {content: ''; display: table; clear: both;}
.shop-list li a dl dt {float: left;}
.shop-list li a dl dd {float: right;}

@media (max-width:1200px) {
	.shop-list li a dl {font-size: 16px;}
}

@media (max-width:992px) {
	.shop-list li a dl {padding: 10px; font-size: 14px;}
}

@media (max-width:768px) {
	.shop-list li {width: 50%;}
	.shop-list li:nth-child(2n) {border-right: solid 1px #fff;}
}













@media (min-width:1240px) {
	#footer {padding: 35px 0;}
	.ft-logo {margin-right: 30px;}
	.ft-menu th {width: 70px;}
	.ft-menu td {padding-right: 30px;}
}

@media (max-width:1240px) {
	.ft-menu {display: none;}
	.ft-info {display: block; float: left;}
	.ft-info h5 {font-size: 12px; font-weight: 400;}
	.ft-other {margin-top: 0;}
}

@media (max-width:768px) {
	#footer {padding: 30px 0; margin-bottom: 80px; }
	.ft-logo {display: none;}
	.ft-info h5,
	.ft-info p {font-size: 11px;}
	.ft-other {float: right; margin-top: 3px; padding-bottom: 0;}
	.ft-other__sns {position: static;}
	.ft-other__sns:after {content: ''; display: table; clear: both;}
	.ft-other__sns li {display: block; float: left; margin-right: 15px;}
	.ft-other__sns li a {background-size: contain !important;}
	.ft-other__sns li a.ft-other__sns--fb {width: 9px; height: 20px;}
	.ft-other__sns li a.ft-other__sns--ist {width: 20px; height: 20px;}
	.ft-other__sns li a.ft-other__sns--ytb {width: 25px; height: 20px;}
	.ft-other__privacy {margin-top: 10px;}
	.ft-other__privacy li {float: none; margin-right: 0;}
	.ft-other__privacy li:last-child {margin-bottom: 0;}
	.ft-other__privacy li a {font-weight: 400; text-align: right; font-size: 11px;}
}

/*Footer End*/ 





@media (min-width:992px) {
	
}

/* 중간 사이즈 이상 */ 
@media (max-width:992px) {

	

	.atst-video {margin: 0 auto; text-align: center;  display: flex; flex-direction: column; justify-content: space-between;}
	.atst-video__frame iframe {width: 80%; height : 300px; }

	.main-event__slider--text p {font-size: 20px; left: 20px; bottom: 20px;}


	.main-event__slider--img {height: 320px;}
	.main-event__slider--text {height: 320px; padding: 20px;}
	.main-event__slider--text h4 {font-size: 60px;}
	.main-event__control {right: 20px; bottom: 20px;}

	.main-event__arrow {position: absolute; right: 0; bottom: 100%; margin-bottom: 20px; width: 80px;}
	.main-event__arrow button {width: 35px; height: 35px; margin-right: 8px; background-size: 6px auto !important;}
	.main-event__dots li {margin-right: 10px;}
	.main-event__dots li.slick-active:after {width: 50px;}
	.link-hover:after {display: none;}


	

}


@media (max-width:768px) {

	#sub-aside {display: none;}
	.sub-back {padding: 10px 0;}
	.paging {margin-top: 30px;}
	.paging a {width: 20px; height: 20px; line-height: 20px; font-size: 14px}
	.paging > a {background-size: 6px auto !important; opacity: 1 !important;}
	.paging ul {margin: 0 10px;}


/* 최소 사이즈 메인메뉴 시작 */
#hd {position: fixed; top: 0; left: 20px; right: 20px; padding-top: 60px; padding-bottom: 20px; z-index: 100; background: #fff; border-bottom: solid 1px #000; -webkit-transition: all 0.3s; transition: all 0.3s;}
	#hd-logo {float: left; -webkit-transition: all 0.3s; transition: all 0.3s;}
	#hd-logo a {display: block; text-indent: -9999px; width: 160px; height: 23px; background: url('./img/common/gnb_logo.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
	#menu {position: fixed; color: #fff; top: 0; left: 0; width: 0px; height: 0px; overflow: hidden; background: #000; z-index: 101; -webkit-transition: all 0.3s; transition: all 0.3s;}
	.menu-home {display: block; width: 22px; height: 22px; text-indent: -9999px; background: url('./img/common/gnb_home.png')no-repeat center center / cover;}
	.menu-user {margin-top: 20px;}
	.menu-user__login {}
	.menu-user__login:after {content: ''; display: table; clear: both;}
	.menu-user__login li {float: left; margin-right: 5px;}
	.menu-user__login li:last-child {margin-right: 0;}
	.menu-user__login li a {display: block; height: 34px; line-height: 32px; padding: 0 20px; border: solid 1px #fff; -webkit-border-radius: 20px; border-radius: 20px;}
	.menu-user__profile {position: relative; height: 60px; padding: 10px 0 10px 80px;}
	.menu-user__profile em {display: block; width: 60px; height: 60px; position: absolute; left: 0; top: 50%; margin-top: -30px; text-indent: -9999px; overflow: hidden; -webkit-border-radius: 50%; border-radius: 50%;}
	.menu-user__profile h5 {font-size: 16px; font-weight: 300; line-height: 1.3;}
	.menu-user__profile h5 span {font-weight: 700; margin-right: 3px;}
	.menu-user__profile p {}
	.menu-list {margin-top: 20px; border-top: solid 1px #fff;}
	.menu-list:after {content: ''; display: table; clear: both;}
	.menu-list > li {}
	.menu-list > li > button,
	.menu-list > li > a {display: block; width: 100%; text-align: left; background: none; border-bottom: solid 1px #fff; color: #fff; height: 36px; line-height: 36px; font-size: 16px; position: relative;}
	.menu-list > li > button + a {display: none;}
	.menu-list > li > button:after {content: ''; display: block; width: 15px; height: 8px; position: absolute; right: 10px; top: 50%; margin-top: -4px; background: url('./img/common/gnb_arrow_down.png')no-repeat center center / cover;}
	.menu-list > li > button.on {color: #C20019; border-bottom-color: #C20019;}
	.menu-list > li > button.on:after {background: url('./img/common/gnb_arrow_up.png')no-repeat center center / cover;}
	.menu-list > li > ul {display: none; border-bottom: solid 1px #fff; padding: 10px 20px;}
	.menu-list > li > ul > li {margin-bottom: 10px;}
	.menu-list > li > ul > li:last-child {margin-bottom: 0;}
	.menu-list > li > ul > li > a {display: inline-block; position: relative; padding-left: 10px;}
	.menu-list > li > ul > li > a:before {content: ''; display: block; width: 3px; height: 3px; background: #fff; position: absolute; left: 0; top: 50%; margin-top: -2px; -webkit-border-radius: 50%; border-radius: 50%;}
	#hd-mo {display: block; float: right; width: 24px; height: 22px; background: none; text-indent: -9999px; padding: 2px 0; position: relative;}
	#hd-mo:before,
	#hd-mo:after {content: '';}
	#hd-mo:before,
	#hd-mo:after,
	#hd-mo span {display: block; height: 2px; background: #333; margin-bottom: 6px; transition: all .3s ease-in-out; -webkit-transition: all .3s ease-in-out;}
	#hd-mo:after {margin-bottom: 0;}
	#hd-mo.on:before {margin-left:0px; transform:rotate(45deg);-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);}
	#hd-mo.on span {opacity:0;}
	#hd-mo.on:after {margin-top:-16px; margin-left:0px; transform:rotate(-45deg);-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);}
	html.lock #menu {width: 100%; height: 100%; padding-top: 60px; padding-left: 20px; padding-right: 20px;}
	html.lock #hd-mo {z-index: 102;}
	html.lock #hd-mo:before,
	html.lock #hd-mo:after,
	
	html.lock #hd-mo span {background: #fff;}
	#hd.scroll {padding-top: 12px; padding-bottom: 12px;}
	#hd.scroll #hd-logo a {width: 130px; height: 19px; margin-top: 3px;}

	.main-event__slider--img {float: none; width: 100%; height: auto; padding-bottom: 67%;}
	.main-event__slider--text {float: none; width: 100%; height: auto; padding: 20px;}
	.main-event__slider--text h4 {font-size: 60px;}
	.main-event__slider--text em {margin-top: 0;}
	.main-event__slider--text h4 {font-size: 44px;}
	.main-event__slider--text p {font-size: 16px; position: static; margin-top: 40px;}
	.main-event__arrow {margin-bottom: 5px;}
	.main-event__arrow button {width: 24px; height: 24px; margin-right: 6px; background-size: 4px auto !important;}
	.main-event__dots li {margin-right: 5px;}
	.main-event__dots li.slick-active:after {width: 50px;}
	.main-event__dots li button {margin: 0 5px; font-size: 12px;}


	.login-title {width: 250px; height: 50px; text-indent: -9999px; margin: 0 auto; background: url('./img/sub/login_logo.png')no-repeat center bottom / 100% auto;}

/* 최소 사이즈 메인메뉴 끝 */



  .main-atst {}
	.main-atst__row {padding-right: 0;}
	.main-atst__menu {width: 100%; position: static;}
	.main-atst__menu li:last-child {border-bottom: none;}
	.main-atst__menu li button,
	.main-atst__menu li a {height: 40px; line-height: 40px; font-size: 16px; padding-left: 0;}
	.main-atst__menu li button:before,
	.main-atst__menu li a:before {display: block; left: auto; right: 10px;}
	.main-atst__menu li button:hover:before,
	.main-atst__menu li a:hover:before {left: auto; right: 10px;}
	.main-atst__list li {width: 50%; }
	.main-atst__list li:nth-child(2n) {border-right: none;}
	.main-atst__fav {width: 20px; height: 16px; background-size: contain !important;}
	.main-atst__fav.on {background: url('./img/common/artist_favorite_on.png')no-repeat center center / cover;}
	.main-atst__link {padding: 10px;}
	.main-atst__link span {font-size: 12px; line-height: 1.2;}
	.main-atst__link h5 {font-size: 16px;}
	.main-atst__link p {display: none; }

	

	/* 최소사이즈  Reservation 시작 */ 
	input[type="checkbox"].chk-big {width: 20px; height: 20px; background-size: 12px auto !important;}
	.res-order__info {padding: 10px 0;}
	.res-order__info h5 {font-size: 16px;}
	.res-order__info ul {font-size: 11px;}
	.res-order__info ul li {margin-right: 10px;}
	.res-order__map {padding: 20px; display: block;}
	.res-order__map--left {display: block; width: 100%; border-right: none; padding: 0;}
	.res-order__map--left img.pc {display: none;}
	.res-order__map--left img.mo {display: block;}
	.res-order__map--right {display: block; width: 100%;}
	.res-order__map--amount {padding: 20px 0;}
	.res-order__map--amount dt {font-size: 14px; margin-bottom: 5px; color: #333;}
	.res-order__map--amount dd h5 {font-size: 14px;}
	.res-order__map--amount dd h5:before {width: 10px; height: 10px; margin-right: 4px;}
	.res-order__map--amount dd p {font-size: 12px;}
	.res-order__map--amount dd p b {font-size: 14px;}
	.res-order__map--info {padding: 20px 0 0;}
	.res-order__map--info dt {color: #333; font-size: 14px; margin-bottom: 5px;}
	.res-order__map--info dd ul li {font-size: 12px; margin-bottom: 2px; padding-left: 6px;}
	.res-order__map--info dd ul li:before {width: 2px; height: 2px;}
	.res-order__chkall {padding: 10px 0;}
	.res-order__chkall input[type="checkbox"] + label {font-size: 14px;}
	.res-order__box {border-bottom: solid 1px #000; display: block;}
	.res-order__report {display: block; width: 100%; padding: 20px 0;}
	.res-order__chk {width: 30px;}
	.res-order__thumb {width: 100px;}
	.res-order__text {padding-left: 20px;}
	.res-order__text span {font-size: 12px;}
	.res-order__text h5 {margin-top: 5px; font-size: 16px;}
	.res-order__text p {font-size: 14px;}
	.res-order__sel {display: block; width: 100%; border-left: none;}
	.res-order__sel dl dt {padding: 5px 10px; font-size: 13px; font-weight: 500; margin-bottom: 10px; background: #f5f5f5; border-bottom: none;}
	.res-order__sel dl dd {padding: 5px 10px;}
	.res-order__sel dl dd h5 {font-size: 14px;}
	.res-order__sel dl dd select {width: 60px;}
	.res-order__sel dl dd p {font-size: 14px; width: 90px;}
	.res-order__sel dl dd p b {font-size: 14px;}
	.res-order__amount {padding: 10px; margin-top: 10px;}
	.res-order__amount p {font-size: 16px;}
	.res-order__amount p b {margin-left: 5px;}
	.res-order__del {padding: 15px 0;}
	.res-order__del button {width: 90px; height: 34px; line-height: 32px; font-size: 12px;}
	.res-order__del p {font-size: 11px;}
	.res-order__total {padding: 15px 0 20px;}
	.res-order__total p {font-size: 14px;}
	.res-order__total p span {font-size: 20px;}
	.res-order__submit {margin: 0 -5px;}
	.res-order__submit li {margin-left: 0; padding: 0 5px; width: 50%;}
	.res-order__submit a,
	.res-order__submit button {width: 100%; font-size: 14px;}
	.res-order__time {margin-top: 5px; font-size: 12px;}

	
	.res-order__add {border-top: none; border-bottom: solid 1px #000;}
	.res-order__add,
	.res-order__add tbody,
	.res-order__add tr,
	.res-order__add td {display: block; width: 100%;}
	.res-order__add tr {border-top: solid 1px #000; padding: 15px 0 15px 160px; position: relative;}
	.res-order__add th,
	.res-order__add td {border-bottom: none; padding: 0;}
	.res-order__add th {position: absolute; left: 0; top: 15px;}
	.res-order__add th img {width: 140px; height : 110px; }
	.res-order__add td {font-size: 12px;}
	.res-order__add td:after {content: ''; display: table; clear: both;}
	.res-order__add button {float: right; display: block; height: 30px; line-height: 28px; padding: 0 10px; font-size: 12px;}

	.res-order__fixed .inner {padding: 0 20px;}
	.res-order__fixed .res-order__total {padding: 15px 0;}
	.res-order__fixed .res-order__total p {height: 40px; line-height: 40px;}
	.res-order__fixed .res-order__submit {padding: 15px 0;}
	.res-order__fixed .res-order__submit li {width: auto;}
	.res-order__fixed .res-order__submit a,
	.res-order__fixed .res-order__submit button {width: 100px; height: 40px; line-height: 40px;}

	.res-complete h4 {padding: 10px 0; font-size: 16px;}
	.res-complete p {font-size: 12px;}
	.res-complete__info {padding: 20px 0;}
	.res-complete__info th,
	.res-complete__info td {padding: 10px; font-size: 14px;}
	.res-complete__info th {width: 100px;}
	.res-complete__info td {}


	.res-order__step--concert {width: 100%;}
	.res-order__step {display: flex; flex-direction : column;  justify-content : space-between; width: 100%; padding: 10px 0px 0px 0px; border-bottom: solid 2px #000;}
	.res-order__step--person{text-align: center; width: 30%; margin: 1px; display: flex; flex-direction : row; justify-content :center; }
	.res-order__step--person label {font-size: 16px; width:50%; font-weight: 500; }
	.res-order__step--person select {font-size: 12px; width:50%; font-weight: 500; }


/* 최소사이즈  Reservation 끝 */ 


/* 최소사이즈 PreReservation */

.main-sch__date.res {margin-top: 30px;}
.main-sch__date.res input[type="text"] {font-size: 18px;}
.main-sch__list.res .main-sch__list__thumb {height: 100px;}
.main-sch__list__sel {width: 50px;}
.main-sch__list__sel button {height: 34px; line-height: 32px; font-size: 13px;}
.main-sch__list__link {padding: 10px 0;}
.main-sch__list__num {width: 40px; font-size: 13px; display: none;}
.main-sch__list__thumb {width: 100px; height: 60px;}
.main-sch__list__text {padding: 0 10px;}
.main-sch__list__text h5 {font-size: 14px;}
.main-sch__list__text p {font-size: 12px;}	

.res-bottom {margin-top: 0;}
.res-info {float: none; background: #f4f4f4; padding: 10px;}
.res-info li {font-size: 12px;}
.res-info li:before {top: 8px;}
.res-submit {background : black; height: 50px; line-height: 50px; width: 100%; -webkit-border-radius: 0px; border-radius: 0px; padding: 0; font-size: 18px;}

/* 최소사이즈 PreReservation */ 


/* 최소사이즈 Artist Detail 시작 */
    .atst-video {margin: 0 -10px;  display: flex; flex-direction: column; justify-content: space-between;}
	.atst-video__arrows {padding-top: 15px;}
	.atst-video__arrows .slick-prev {margin-right: 4px;}
	.atst-detail,
	.atst-detail tbody,
	.atst-detail tr,
	.atst-detail th,
	.atst-detail td {display: block; width: 100%;}
	.atst-detail tr {position: relative;}
	.atst-detail td {padding: 10px; border-left: none;}
	.atst-detail th.atst-detail__pic {width: 100%;}
	.atst-detail td.atst-detail__name strong {font-size: 24px;}
	.atst-detail td.atst-detail__name span {font-size: 16px;}
	.atst-detail td.atst-detail__fav {width: auto; position: absolute; right: 10px; bottom: 20px; border-bottom: none; padding: 0;}
	.atst-detail td.atst-detail__fav button {width: auto;}
	.atst-detail td.atst-detail__fav button:before {margin-right: 0;}
	.atst-detail td.atst-detail__fav button span {display: none;}
	.atst-detail td.atst-detail__text h5 {font-size: 18px;}
	.atst-detail td.atst-detail__text p {margin-top: 5px;}
	.atst-detail td.atst-detail__part {}
	.atst-pfm,
	.atst-pfm tbody,
	.atst-pfm tr {display: block; width: 100%;}
	.atst-pfm {border-top: solid 1px #000;}
	.atst-pfm tr {border-bottom: solid 1px #000; position: relative; padding: 10px 0;}
	.atst-pfm td {border-top: none; border-bottom: none; padding: 0; text-align: left; font-size: 14px; display: inline; font-size: 13px;}
	.atst-pfm td.atst-pfm__date {}
	.atst-pfm td.atst-pfm__day {}
	.atst-pfm td.atst-pfm__time {display: block; width: 100%; font-size: 18px; font-weight: 700;}
	.atst-pfm td.atst-pfm__part {display: block; width: 100%; margin-top: 5px;}
	.atst-pfm td.atst-pfm__btn {position: absolute; right: 0; top: 50%; margin-top: -17px;}
	.atst-pfm td.atst-pfm__btn button {padding: 0 10px; height: 30px; line-height: 28px; font-size: 13px;}
/* 최소사이즈 Artist Detail 끝 */

}