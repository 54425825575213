#my-wrap{
    margin: 0px; 
}


#my-main { display:flex; flex-direction: row;  justify-content : left ; }
#my-aside { margin : 0rem ; display : flex; flex-direction : column ;  justify-content : left ; border-right : solid 1px black; width : 200px; }
#my-aside li{ border-bottom: solid 1px black; font-size : 1.7rem ; color: #929292; line-height : 5rem; font-weight : 700; }
#my-aside li:hover{ color :#C20019; }
#my-content { display: flex; flex-direction:row ; justify-content: left; padding : 0px; margin : 0px ; width: 100%;  }

#mysub-cont {display:flex; max-width: 100%; flex-direction: column;}
#mysub-cont-teamsetup {display:flex; max-width: 500px; flex-direction: column;}

.join-form {margin-top: 40px;}
.join-form dt {font-size: 16px; font-weight: 700;}
.join-form dt p {color: #acacac; font-weight: 300;}
.join-form dd {margin-top: 10px;}


.comment-span{font-size : 12px; font-weight: 300; }

/* mypage */
.mpg-title {padding: 0 20px; border-bottom: solid 1px #000;  }
.mpg-title:after {content: ''; display: table; clear: both;}
.mpg-title h4 { line-height: 5rem; float: left; font-size: 20px;}
.mpg-title ul {float: right; padding: 15px 0;}
.mpg-title ul:after {content: ''; display: table; clear: both;}
.mpg-title ul li {float: left; margin-right: 20px; padding-right: 20px; position: relative;}
.mpg-title ul li:last-child {margin-right: 0; padding-right: 0;}
.mpg-title ul li:after {content: ''; display: block; width: 1px; height: 10px; background: #929292; position: absolute; right: 0; top: 50%; margin-top: -5px;}
.mpg-title ul li:last-child:after {display: none;}
.mpg-title ul li button,
.mpg-title ul li a {display: block; color: #929292; font-size: 14px; height: 24px; line-height: 24px; background: none;}
.mpg-title ul li button.on,
.mpg-title ul li a.on {color: #000; font-weight: 500;}
.mpg-mem__top {display: table; width: 100%; padding: 25px 50px; background: #f4f4f4;}
.mpg-mem__top > div {display: table-cell; vertical-align: middle;}
.mpg-mem__top--profile {width: 115px; position: relative;}
.mpg-mem__top--profile em {display: block; padding-bottom: 100%; -webkit-border-radius: 50%; border-radius: 50%;}
.mpg-mem__top--profile button {display: block; width: 24px; height: 24px; background: #fff; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px; position: absolute; right: 3px; bottom: 3px;}
.mpg-mem__top--profile button:before,
.mpg-mem__top--profile button:after {content: ''; display: block; background: #d9d9d9; position: absolute; left: 50%; top: 50%;}
.mpg-mem__top--profile button:before {width: 12px; height: 2px; margin-left: -6px; margin-top: -1px;}
.mpg-mem__top--profile button:after {width: 2px; height: 12px; margin-left: -1px; margin-top: -6px;}
.mpg-mem__top--text {padding-left: 15px;}
.mpg-mem__top--text h5 {font-size: 18px; font-weight: 400;}
.mpg-mem__top--text h5 b {font-weight: 700;}
.mpg-mem__top--text p {font-size: 16px;}
.mpg-mem__code {display: none;}
.mpg-mem__code--input {font-size: 14px;}
.mpg-mem__code--input.ok {border: none;}
.mpg-mem__info th,
.mpg-mem__info td {border-top: solid 1px #929292; border-bottom: solid 1px #929292; font-size: 16px;}
.mpg-mem__info th {padding-left: 50px; text-align: left; font-weight: 400; width: 180px; position: relative;}
.mpg-mem__info th:after {content: ''; display: block; width: 1px; background: #929292; position: absolute; right: 0; top: 10px; bottom: 10px;}
.mpg-mem__info th span {display: block; font-size: 14px; color: #808080;}
.mpg-mem__info td {color: #808080; padding: 10px 20px;}
.mpg-mem__btn {display: inline-block; vertical-align: middle; min-width: 80px; height: 34px; line-height: 32px; border: solid 1px #000; font-size: 14px; text-align: center; padding: 0 20px; margin-left: 15px; background: #fff; -webkit-border-radius: 30px; border-radius: 20px;}
.mpg-mem__btn.gry {border-color: #7A7A7A; color: #7A7A7A;}
.mpg-mem__btn.bk {background: #000; color: #fff;}
.mem__status_red {font-size : 16px; text-align : center ; border: solid 1px #C20019; position: absolute; left: 50%; width : 100px; border-color: #C20019; color: #C20019}
.mpg-btn {padding: 20px 50px;}
.mpg-btn:after {content: ''; display: table; clear: both;}
.mpg-btn li {float: left; margin-right: 10px;}
.mpg-btn li:last-child {margin-right: 0;}
.mpg-btn li a {display: block; width: 120px; height: 42px; line-height: 40px; text-align: center; font-size: 16px; border: solid 1px #000; -webkit-border-radius: 30px; border-radius: 30px;}
.mpg-btn li a.bk {background: #000; color: #fff;}

.mpg-res__list {}
.mpg-res__list li {border-bottom: solid 1px #000;}
.mpg-res__list li:last-child {}
.mpg-res__toggle {position: relative; padding: 10px 80px 10px 50px; cursor: pointer;}
.mpg-res__toggle:after {content: ''; display: table; clear: both;}
.mpg-res__toggle:before {content: ''; display: block; width: 17px; height: 9px; position: absolute; right: 20px; top: 50%; margin-top: -5px; background: url('../img/common/toggle_arrow.png')no-repeat center bottom / 100% auto;}
.mpg-res__toggle.on:before {background-position: center top;}
.mpg-res__toggle h5 {display: block; float: left; height: 34px; line-height: 34px; font-size: 18px; color: #797979; max-width:calc(100% - 220px); overflow: hidden; text-overflow: ellipsis;}
.mpg-res__toggle.on h5 {color: #000;}
.mpg-res__toggle h4 {display: block; float: left; height: 34px; line-height: 34px; font-size: 12px; color: #797979; margin-left : 20px }
.mpg-res__toggle span {display: block; float: left;}
.mpg-res__toggle button {display: block; float: right;}
.mpg-res__dtl { display: block ; padding: 0 50px 15px; background: #f4f4f4; border-top: solid 1px #000; transition: 1s;}
.mpg-res__dtl--tb {}
.mpg-res__dtl--tb th,
.mpg-res__dtl--tb td {padding: 10px 0; font-size: 16px;}
.mpg-res__dtl--tb th {font-weight: 400; position: relative; width: 100px;}
.mpg-res__dtl--tb th:after {content: ''; display: block; width: 1px; height: 20px; background: #929292; position: absolute; right: 0; top: 50%; margin-top: -10px;}
.mpg-res__dtl--tb td {padding-left: 20px; font-weight: 500;}
.mpg-res__dtl--info {position: relative; padding-left: 30px; color: #757575; font-size: 14px;}
.mpg-res__dtl--info:before {content: '!'; color: #828282; display: block; width: 20px; height: 20px; line-height: 18px; position: absolute; left: 0; top: 50%; margin-top: -10px; text-align: center; box-sizing: border-box; border: solid 1px #828282; -webkit-border-radius: 50%; border-radius: 50%;}
.mpg-res__dtl--cancel {display: none;}
.mpg-team {padding: 0 50px; max-width: 420px;}
.mpg-team__profile {position: relative; text-align: center; margin: 0px 10px 20px 0px;}
.mpg-team__profile img {width : 100px ; height : 100px ; object-fit:cover ; border-radius: 50%; }
.mpg-team__profile em.dotted:after {content: ''; display: block; position: absolute; left: 0; top: 0; width: 100%; height: 100%; border: dashed 2px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%;}
.mpg-team__profile p {font-size: 14px; font-weight: 700; margin-top: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.mpg-team__profile span {display: block; font-size: 14px;}

.mpg-team__profile--leader {position: absolute; top :80px ; right: 0px; }
.mpg-team__profile--leader > button {display: block; width: 22px; height: 22px; background: #fff url('../img/sub/team-profile_leader.png')no-repeat 5px center / 11px auto; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; }

.mpg-team__profile--setting {position: absolute; top :80px ; right: 0px; }
.mpg-team__profile--setting > button {display: block; width: 22px; height: 22px; background: #fff url('../img/sub/team-profile_setting.png')no-repeat 5px center / 11px auto; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; }
.mpg-team__profile--setting dl {position: absolute; border: solid 1px #000; background: #fff; width: 120px; z-index: 99; left: 100%; top: 0px; margin-left: 5px;}
.mpg-team__profile--setting dl dt {border-bottom: solid 1px #000;}
.mpg-team__profile--setting dl dt:last-child {border-bottom: none;}
.mpg-team__profile--setting dl dt button {z-index: 99;display: block; width: 100%; background: none; text-align: left; padding: 5px 10px; font-size: 13px;}
.mpg-team__profile--setting dl dt button:hover {background: #757474;}
.join-artist__img{ display:flex ; flex-direction : row }
.join-artist__img li:nth-child(4n) .mpg-team__profile--setting ul {left: auto; right: 100%; margin-right: 5px;}
.mpg-team__profile--add {display: block; width: 24px; height: 24px; background: #fff; z-index: 1; border: solid 1px #d9d9d9; -webkit-border-radius: 50%; border-radius: 50%; text-indent: -9999px; position: absolute; right: 0; top: 80px;}
.mpg-team__profile--add:before,
.mpg-team__profile--add:after {content: ''; display: block; background: #d9d9d9; position: absolute; left: 50%; top: 50%;}
.mpg-team__profile--add:before {width: 12px; height: 2px; margin-left: -6px; margin-top: -1px;}
.mpg-team__profile--add:after {width: 2px; height: 12px; margin-left: -1px; margin-top: -6px;}


#popup.team_setup {text-align: left; top: 0; left: 0; width: 100%; height: 100%; padding: 40px 30px; overflow-y: auto;  background: none; border: none; transform: translate(0, 0); -webkit-transform: translate(0, 0);}
.pop-team__wrap {display: table; width: 100%; height: 100%;}
.pop-team__inner {display: table-cell; vertical-align: middle; width: 100%;}
.pop-team__box {background: #fff; border: solid 2px #000; width: 100%; max-width: 460px; margin: 0 auto; padding: 20px 30px; position: relative;}
.pop-team__close {display: block; width: 30px; height: 30px; background: none; position: absolute; right: 10px; top: 10px; text-indent: -9999px;}
.pop-team__close:before,
.pop-team__close:after {content: ''; display: block; background: #000; position: absolute; left: 50%; top: 50%; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
.pop-team__close:before {width: 21px; height: 1px; margin-left: -10px;}
.pop-team__close:after {width: 1px; height: 21px; margin-top: -10px;}
.pop-team__title {text-align: center; font-size: 20px;}
.pop-team__title2 {font-size: 14px; margin-top: 30px; margin-bottom: 10px;}
.pop-team__title3 {font-size: 14px; font-weight: 400; margin-top: 30px; margin-bottom: 10px;}
.pop-team__search {margin-top: 20px; padding: 0 20px;}
.pop-team__search--inner {position: relative; padding-right: 50px; border: solid 1px #acacac; -webkit-border-radius: 20px; border-radius: 20px; overflow: hidden;}
.pop-team__search input[type="text"] {display: block; width: 100%; height: 38px; border: none; font-size: 14px;}
.pop-team__search input[type="text"]:focus {outline: none;}
.pop-team__search a,
.pop-team__search button {display: block; width: 40px; height: 40px; position: absolute; right: 5px; top: -1px; text-indent: -9999px; background: url('../img/common/search.png')no-repeat center center / 20px auto;}
.pop-team__list {width: 100%;}
.pop-team__list th,
.pop-team__list td {padding: 5px; font-size: 14px;}
.pop-team__list th {border-top: solid 1px #000; border-bottom: solid 1px #000;}
.pop-team__list td {border-bottom: solid 1px #d9d9d9;}
.pop-team__list tr.on {background: #f4f4f4;}
.pop-team__list--none {text-align: center; padding: 30px 0;}
.pop-team__list--none h5 {color: #6d6a6a; font-size: 14px;}
.pop-team__list--none p {color: #a4a4a4; font-size: 12px;}
.pop-team__profile {position: relative; padding: 20px 0 20px 65px; border-top: solid 1px #000; margin-bottom: -20px;}
.pop-team__profile em {display: block; width: 50px; height: 50px; position: absolute; left: 0; top: 50%; margin-top: -25px; -webkit-border-radius: 50%; border-radius: 50%;}
.pop-team__profile h5 {font-size: 14px;}
.pop-team__profile p {font-size: 14px;}
.pop-team__inst {}
.pop-team__inst:after {content: ''; display: table; clear: both;}
.pop-team__inst li {margin-right: 5px; margin-bottom: 5px; float: left;}
.pop-team__inst li:last-child {margin-right: 0;}
.pop-team__inst li span {margin-left: 0; display: block; height: 32px; line-height: 30px; text-align: center; padding: 0 10px; border: solid 1px #acacac; -webkit-border-radius: 20px; border-radius: 20px; font-size: 12px;}
.pop-team__video {}
.pop-team__video li {border-bottom: solid 1px #d9d9d9; padding: 10px 0;}
.pop-team__video li:last-child {}
.pop-team__video li a {}
.pop-team__submit {display: block; height: 44px; line-height: 44px; width: 100%; background: #000; color: #fff; font-size: 14px; margin-top: 30px; font-size: 14px;}
.pop-team__submit.disabled {background: #d9d9d9;}

#my-team { }


.my-team__tb th {border-top: solid 1px #000; border-bottom: solid 1px #000; padding: 10px 0; font-size: 14px; font-weight: 500;}
.my-team__tb td {padding: 20px 0; border-bottom: solid 1px #ddd; text-align: center; font-size: 16px; vertical-align: top;}
.my-team__tb td span {display: block; color: #929292; font-size: 12px;}
.my-team__tb td a,
.my-team__tb td button {display: inline-block; vertical-align: middle; text-align: center; width: 70px; height: 34px; line-height: 32px; border: solid 1px #000; font-size: 14px; -webkit-border-radius: 20px; border-radius: 20px; background: none;}
.my-team__tb td a.bk,
.my-team__tb td button.bk {background: #000; border-color: #000; color: #fff;}
.my-team__tb td a.disabled,
.my-team__tb td button.disabled {background: #d9d9d9; border-color: #d9d9d9; color: #fff;}
.my-team__tb td.my-team__tb--image img {width : 50px; height : 50px; object-fit : cover ; }


.my-team__list {}
.my-team__list li {border-top: solid 1px #000;}
.my-team__list li:last-child {border-bottom: solid 1px #000;}
.my-team__list li dl {}
.my-team__list li dl dt {padding: 10px 20px; border-bottom: solid 1px #d9d9d9;}
.my-team__list li dl dt a {display: inline-block; font-size: 16px; font-weight: 700; position: relative; padding-right: 20px;}
.my-team__list li dl dt a:after {content: ''; display: block; width: 8px; height: 14px; position: absolute; right: 0; top: 50%; margin-top: -7px; background: url('../img/common/paging_next.png')no-repeat center center / cover;}
.my-team__list li dl dd {padding: 15px 20px; font-size: 16px;}
.my-team__list li dl dd table th,
.my-team__list li dl dd table td {padding: 5px 0;}
.my-team__list li dl dd table th {width: 100px; font-weight: 400;}
.my-team__list li dl dd table td {font-weight: 500;}
.my-team__list li dl dd table td span {display: inline-block; vertical-align: middle; text-align: center; width: 70px; height: 32px; line-height: 30px; border: solid 1px #000; font-size: 14px; -webkit-border-radius: 20px; border-radius: 20px; background: none;}
.my-team__list li dl dd table td span.red {border-color: #C20019;}

#popup.team_info {text-align: left; top: 0; left: 0; width: 100%; height: 100%; padding: 40px 30px; overflow-y: auto;  background: none; border: none; transform: translate(0, 0); -webkit-transform: translate(0, 0);}
#popup.team_info .pop-team__box {max-width: 960px; padding: 40px;}
.my-team__profile {display: table; width: 100%;}
.my-team__profile dt {display: table-cell; vertical-align: top; width: 260px;}
.my-team__profile dt img {display: block; width: 100%;}
.my-team__profile dd {display: table-cell; vertical-align: top; padding-left: 30px;}
.my-team__profile dd h5 {margin-top: 30px; font-size: 20px; font-weight: 700; border-bottom: solid 1px #000; padding-bottom: 10px; margin-bottom: 10px;}
.my-team__profile dd h5:first-child {margin-top: 0;}
.my-team__profile dd p {font-size: 14px;}



@media (max-width:768px) {

	.mpg-title {padding: 0 20px;}
	.mpg-title h4 {height: 40px; line-height: 40px; font-size: 16px;}
	.mpg-title ul {padding: 10px 0;}
	.mpg-title ul li {margin-right: 10px; padding-right: 10px;}
	.mpg-title ul li button,
	.mpg-title ul li a {font-size: 13px; height: 20px; line-height: 20px;}
	.mpg-mem__top {padding: 15px;}
	.mpg-mem__top--profile {width: 80px;}
	.mpg-mem__top--profile button {right: 0; bottom: 0;}
	.mpg-mem__top--text {padding-left: 20px;}
	.mpg-mem__top--text h5 {font-size: 16px;}
	.mpg-mem__top--text p {font-size: 14px;}	
	.mpg-mem__code--input {width:calc(100% - 80px);}
	.mpg-mem__info th,
	.mpg-mem__info td {font-size: 14px;}
	.mpg-mem__info th {padding-left: 0; width: 90px;}
	.mpg-mem__info td {padding: 10px;}
	.mpg-mem__btn {min-width: 60px; height: 30px; line-height: 28px; font-size: 13px; padding: 0 10px; margin-left: 10px;}
	.mpg-btn {padding: 15px 0;}
	.mpg-btn li {margin-right: 5px;}
	.mpg-btn li a {width: 100px; height: 40px; line-height: 38px; font-size: 14px;}
	.mpg-res__toggle {padding: 10px 40px 10px 10px;}
	.mpg-res__toggle:before {right: 10px;}
	.mpg-res__toggle h5 {height: 30px; line-height: 30px; font-size: 14px; max-width:calc(100% - 100px);}
	.mpg-res__toggle button {display: none;}
	.mpg-res__dtl {padding: 10px 15px 15px;}
	.mpg-res__dtl--tb th,
	.mpg-res__dtl--tb td {padding: 5px 0; font-size: 13px;}
	.mpg-res__dtl--tb th {width: 70px;}
	.mpg-res__dtl--tb th:after {height: 12px; margin-top: -6px;}
	.mpg-res__dtl--tb td {padding-left: 10px;}
	.mpg-res__dtl--info {padding-left: 20px; font-size: 12px;}
	.mpg-res__dtl--info:before {width: 16px; height: 16px; line-height: 14px; margin-top: 0; top: 3px;}
	.mpg-res__dtl--cancel {display: block; width: 100%; height: 44px; line-height: 42px; background: #fff; border: solid 1px #000; text-align: center; font-size: 14px; margin-top: 10px;}
	.mpg-team {padding: 0 20px; max-width: 400px;}
	#popup.team_setup {padding: 0;}
	
	#my-team {}
	.my-team__tb thead {display: none;}
	.my-team__tb td {display: block; width: 100%;}
	.my-team__tb tr {border-bottom: solid 1px #000; margin-bottom: 30px;}
	.my-team__tb tbody tr:last-child {margin-bottom: 0; border-bottom: solid 1px #000; padding-bottom: 15px;}
	.my-team__tb td {padding: 10px; border-bottom: none; text-align: left; font-size: 14px;}
	.my-team__tb td span {display: inline; font-size: 14px;}
	.my-team__tb td.my-team__tb--team {font-size: 18px; font-weight: 500; border-bottom: solid 1px #d9d9d9; padding: 10px 10px;}
	.my-team__tb td.my-team__tb--image img {width : 50px; height : 50px; object-fit : cover ; margin-left : 30px;  }
	.my-team__tb td.my-team__tb--name,
	.my-team__tb td.my-team__tb--mail,
	.my-team__tb td.my-team__tb--detail,
	.my-team__tb td.my-team__tb--status,
	.my-team__tb td.my-team__tb--count {display: table; width: 100%; margin-top: 15px;  }
	.my-team__tb td.my-team__tb--name:before,
	.my-team__tb td.my-team__tb--mail:before,
	.my-team__tb td.my-team__tb--detail:before,
	.my-team__tb td.my-team__tb--status:before,
	.my-team__tb td.my-team__tb--count:before {display: table-cell; vertical-align: top; width: 80px; font-weight: 500;padding : 0px 10px;}
	.my-team__tb td.my-team__tb--name:before {content: '리더명';}
	.my-team__tb td.my-team__tb--image:before {content: '리더이미지';}
	.my-team__tb td.my-team__tb--detail:before {content: '상세'; padding-top: 5px;}
	.my-team__tb td.my-team__tb--status:before {content: '상태'; padding-top: 5px;}
	.my-team__tb td.my-team__tb--status span {display: block; margin-top: 5px;}
	.my-team__tb td.my-team__tb--count:before {content: attr(data-th);}
	.my-team__list li dl dt {padding: 10px 0;}
	.my-team__list li dl dd {padding: 10px 0; font-size: 14px;}
	.my-team__list li dl dd table th,
	.my-team__list li dl dd table td {padding: 3px 0;}
	.my-team__list li dl dd table th {width: 80px;}
	.my-team__list li dl dd table td span {width: 60px; height: 28px; line-height: 26px;  font-size: 12px;}

	#popup.team_info {padding: 0;}
	#popup.team_info .pop-team__box {padding: 40px 20px;}
	.my-team__profile {display: block; width: 100%;}
	.my-team__profile dt {display: block; width: 200px; margin: 0 auto;}
	.my-team__profile dd {display: block; padding-left: 0;}
	.my-team__profile dd h5:first-child {margin-top: 30px;}

}

@media (max-width:600px) {
	#my-aside { display :none ;}
}





/* about */
#about {}
.about-top img {display: block; max-width: 100%;}
.about-text {}
.about-text dt {padding: 10px 20px; font-size: 22px; border-bottom: solid 1px #000; font-weight: 700;}
.about-text dd {position: relative; padding: 30px 30px 30px 240px;}
.about-text dd:after {content: ''; display: table; clear: both;}
.about-text dd:before {content: ''; display: block; width: 200px; position: absolute; left: 0; top: 15px; bottom: 15px; border-right: solid 1px #929292; background: url('../img/common/footer_logo.png')no-repeat center center / 143px auto;}
.about-text dd p {font-size: 16px;}
.about-text dd strong {display: block; margin-top: 20px; font-size: 18px;}
.about-menu {}
.about-menu dt {padding: 10px 20px; font-size: 22px; border-bottom: solid 1px #000; font-weight: 700;}
.about-menu dt span {display: inline-block; vertical-align: middle; padding-left: 40px; position: relative; color: #C20019; font-size: 14px; font-weight: 400; margin-left: 20px;}
.about-menu dt span:before {content: ''; display: block; width: 33px; height: 16px; position: absolute; left: 0; top: 50%; margin-top: -8px; background: url('../img/sub/menu_dots.png')no-repeat center center / cover;}
.about-menu dd {position: relative; padding: 30px 30px 30px 240px;}
.about-menu dd:after {content: ''; display: table; clear: both;}
.about-menu dd:before {content: ''; display: block; width: 200px; position: absolute; left: 0; top: 15px; bottom: 15px; border-right: solid 1px #929292; background: url('../img/sub/menu_logo.png')no-repeat center center / 145px auto;}
.about-menu dd h5 {display: block; font-size: 18px;}
.about-menu dd p {font-size: 16px; margin-top: 10px;}
.loc-map {height: 400px; position: relative; }
.loc-map iframe {display: block; position: absolute; left: 0; top: 0;}
.loc-info dl {border-top: solid 1px #000;}
.loc-info dl dt {border-bottom: solid 1px #000; font-size: 22px; font-weight: 700; padding: 5px 15px;}
.loc-info dl dd {padding: 20px 15px; font-size: 16px;}
.loc-info dl dd ul li {margin-bottom: 5px; position: relative; padding-left: 8px;}
.loc-info dl dd ul li:last-child {margin-bottom: 0;}
.loc-info dl dd ul li:before {content: ''; display: block; width: 3px; height: 3px; background: #000; -webkit-border-radius: 50%; border-radius: 50%; position: absolute; left: 0; top: 11px;}

@media (max-width:992px) {
	.about-text dt {padding: 10px 0;}
	.about-menu dt {padding: 10px 0;}
}

@media (max-width:768px) {
	.about-text dd {padding: 20px 0;}
	.about-text dd:before {display: none;}
	.about-text dd p {font-size: 14px;}
	.about-text dd strong {font-size: 16px;}
	.about-menu dt span {display: block; margin-left: 0;}
	.about-menu dd {padding: 20px 0;}
	.about-menu dd:before {display: none;}
	.about-menu dd h5 {font-size: 16px;}
	.about-menu dd p {font-size: 14px;}	
	.loc-map {height: 240px;}
	.loc-info dl dt {font-size: 16px; padding: 5px 0;}
	.loc-info dl dd {padding: 15px 0; font-size: 14px;}
	.loc-info dl dd ul li {margin-bottom: 3px;}
	.loc-info dl dd ul li:before {top: 9px;}

}



