
    .container{
        background-color: black;
        overflow: hidden ;
        flex-grow : 1 ; 
        flex-shrink: 1;
        font-size : 1vw; 
        margin : 1em 0em; 
        background: #000; position: relative;

    }

    .artistimage{
            
        display:flex;
        flex-direction: row;
        width:50%; 
        width: 50%; height: 440px;
    }
    
    .artistimage img{ width:100%; }
    .artisttext{
        z-index: 1;
        background-color: black;
        padding : 0em 2em ; 
        color :white;
        width:50%;
        float: left; width: 50%; height: 440px; padding: 20px; 
        position: relative;
        
    }

    .artisttext em{ display: block; font-size: 20px; color: #C20019; margin-top: 40px; font-family: 'Noto Serif', serif;  }
    .artisttext h4{font-size: 90px; color: #fff; line-height: 1.0; font-weight: 400; }
    .artisttext p{font-size: 30px; color: #fff; position: absolute; left: 20px; bottom: 20px;}

    .artisttext button{
        display: block; float: left; width: 45px; height: 45px; margin-right: 10px; border: solid 1px #fff; text-indent: -9999px; -webkit-border-radius: 50%; border-radius: 50%;
    }

    .righttext{
        float: left; width: 50%; height: 440px; padding: 20px; position: relative;
        
    }
    .leftright {  width : 200px;   position: absolute; left: 150%; bottom: 78%;   }
    .leftbutton{  background: url('../img/main/main_banner_arrow_prev.png')no-repeat center center / 8px auto;  } 
    .rightbutton{ background: url('../img/main/main_banner_arrow_next.png')no-repeat center center / 8px auto;  } 

 
    .artisttext p{
        font-size: 2em ; 
        line-height: 2em;
    }

    .main-event__dots{
        font-size: 1.5em ; 
        padding : 0px; 
        margin : 0px; 
        line-height: 2em;
    }




@media (min-width:600px) {

    .container{
        display:flex; 
        flex-direction: row;
        justify-content: space-between;
        
    }

    .artistimage{  width:50%;  }
    .artisttext{ width:50%;}
   
}

@media (min-width:900px) {
    .container{ font-size : 1rem; }

}


@media (max-width:600px) {
    .container{
        display:flex; 
        flex-direction: column;
        justify-content: space-between;
        font-size : 1.5vw; 

    }

    .artistimage{ width:100%; }
    .artisttext{  width:100%;   }


}












