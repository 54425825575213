@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto Sans KR:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: 'Trade Gothic LT';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Trade Gothic LT Light.eot');
  src: url('./fonts/Trade Gothic LT Light.eot?#iefix') format('embedded-opentype'),
       url('./fonts/Trade Gothic LT Light.otf') format('otf'),
       url('./fonts/Trade Gothic LT Light.svg#Trade%20Gothic%20LT%20Light') format('svg'),
       url('./fonts/Trade Gothic LT Light.ttf') format('truetype'),
       url('./fonts/Trade Gothic LT Light.woff') format('woff'),
       url('./fonts/Trade Gothic LT Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Trade Gothic LT';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Trade Gothic LT.eot');
  src: url('./fonts/Trade Gothic LT.eot?#iefix') format('embedded-opentype'),
       url('./fonts/Trade Gothic LT.otf') format('otf'),
       url('./fonts/Trade Gothic LT.ttf') format('truetype'),
       url('./fonts/Trade Gothic LT.woff') format('woff'),
       url('./fonts/Trade Gothic LT.woff2') format('woff2'),
       url('./fonts/Trade Gothic LT.svg#Trade%20Gothic%20LT') format('svg');
}

@font-face {
  font-family: 'Trade Gothic LT';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Trade Gothic LT Bold.eot');
  src: url('./fonts/Trade Gothic LT Bold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/Trade Gothic LT Bold.svg#Trade%20Gothic%20LT%20Bold') format('svg'),
       url('./fonts/Trade Gothic LT Bold.otf') format('otf'),
       url('./fonts/Trade Gothic LT Bold.ttf') format('truetype'),
       url('./fonts/Trade Gothic LT Bold.woff') format('woff'),
       url('./fonts/Trade Gothic LT Bold.woff2') format('woff2');
}